<script setup lang="ts">
import type { SelectionModeEnum } from "@lxc/app-device-common";
import type LxcTable from "@lxc/app-device-common/src/components/LxcTable.vue";
import type { DtwinI } from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import type { ComputedRef, FunctionalComponent, Ref, SVGAttributes } from "vue";
import { useAcl } from "vue-simple-acl";
import { getConnectivityStatusParameters } from "~/components/dtwins/dtwinsList/connectivity/LxcDtwinsListConnectivityStatus.helper";
import {
  DeviceCompatibilityQuickFilter,
  DisplayColumns,
  DtwinsListDisplayContext,
} from "~/components/dtwins/dtwinsList/dtwinsList.type";
import type { QuickActionButtonConfigI } from "~/components/shared/LxcQuickActionsToolbar.model";
import { useDtwinModels } from "~/composables/useDtwinModels";
import { useDtwin, useDtwins } from "~/composables/useDtwins";
import type { SearchMode } from "~/composables/useSearch";
import { useSector } from "~/composables/useSector";
import { PATHS } from "~/constants/paths";
import { useSectorStore } from "~/stores/useSectorStore";
import { ACL_ROLES, Filters } from "~/types";
import type { ColumnNameAndWidthI } from "~/types/table.type";
import { formatIsoDate } from "~/utils/date-tools";
import filtersUtils from "~/utils/filters.utils";
import { calculateTableMinWidth, getColumnWidth } from "~/utils/table.utils";
import ILxcAlertTriangle from "~icons/lxc/alert-triangle";
import ILxcCheckCircle from "~icons/lxc/check-circle";
import ILxcFolderPlus from "~icons/lxc/folder-plus";
import ILxcTrash2 from "~icons/lxc/trash-2";

const props = defineProps<{
  reload?: boolean;
  tableOnly?: boolean;
  fleetUid?: string;
  columns?: DisplayColumns[];
  defaultFilters?: Map<Filters, any>;
  searchMode?: SearchMode;
  searchBarClass?: string | string[];
  noQuickActionMenu?: boolean;
  noQuickFilters?: boolean;
  context?: DtwinsListDisplayContext;
  isRemovingFromFleet?: boolean;
  notInFleetUid?: string;
  selectedDtwins?: DtwinI[];
  useQueryParametersForPagination?: boolean;
  rowClickSelectionMode?: SelectionModeEnum;
  displayDeviceCompatibilityQuickFilter?: boolean;
  compatibilityCriteria?: string;
  noRedirection?: boolean;
  clickable?: boolean;
  hideAddToFleetPanel?: boolean;
}>();

const emit = defineEmits([
  "update:reload",
  "update:selected-dtwins",
  "removeFromFleet",
  "rowClick",
]);

const {
  isLoading,
  results,
  error,
  setFilter,
  fetchData,
  search,
  onSearch,
  searchOnSectors,
  filters,
} = useDtwins(props.searchMode, props.useQueryParametersForPagination);

const {
  isLoading: isLoadingModels,
  error: errorModels,
  results: models,
  fetchAllModels,
} = useDtwinModels();

const acl = useAcl();
const { t } = useI18n();
const router = useRouter();
const { getSectorLabel } = useSector();
const { getDtwinTypeLabel } = useDtwin();
const { selectedSectors } = storeToRefs(useSectorStore());

const isAllowedToManageFleet = computed(() => acl.can(ACL_ROLES.DVTM_DVT_ADM));

interface DeviceCompatibilityQuickFilterConfigI {
  filterType: DeviceCompatibilityQuickFilter;
  icon?: FunctionalComponent<SVGAttributes>;
}

/**
 * Reload data if global sector filter has been applied
 */
watch(selectedSectors, () => executeSearch(true));

function applyDefaultFilters() {
  if (props.defaultFilters) {
    for (const [filter, value] of props.defaultFilters) {
      setFilter(filter, value || "");
    }
  }
}

applyDefaultFilters();

watch(
  () => props.defaultFilters,
  () => {
    applyDefaultFilters();
    reloadData();
  },
);

watch(
  () => props.reload,
  (reload) => {
    if (reload) {
      reloadData();
      emit("update:reload", false);
    }
  },
);

const tableRef = ref<InstanceType<typeof LxcTable>>();

const searchQuery = computed({
  get() {
    return (filters.get(Filters.DTWIN_NAME_OR_SERIAL_NUMBER) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.DTWIN_NAME_OR_SERIAL_NUMBER, searchQuery);
  },
});

// This computed values contains the page loading state to avoid the display of
// multiple spinners.
const isLoadingPage = computed(() => {
  return (
    (isLoadingModels.value && isLoading.value) ||
    (results.value === null && errorModels.value === undefined)
  );
});

async function reloadData(page?: number, pageSize?: number) {
  const params = new Map();
  if (
    selectedCompatibilityFilter.value ===
    DeviceCompatibilityQuickFilter.COMPATIBLE_DEVICES
  ) {
    params.set("compatibilityCriteria", props.compatibilityCriteria);
  } else if (
    props.compatibilityCriteria &&
    selectedCompatibilityFilter.value ===
      DeviceCompatibilityQuickFilter.INCOMPATIBLE_DEVICES
  ) {
    params.set(
      "compatibilityCriteria",
      filtersUtils.negateRSQLExpression(props.compatibilityCriteria),
    );
  }
  await fetchData(page, pageSize, params);
  setSelection();
}

/**
 * Select Dtwins
 */
const setSelection = () => {
  if (props.selectedDtwins && tableRef.value && tableRef.value.data) {
    tableRef.value.toggleTableSelectionMode(props.selectedDtwins.length > 0);
    for (const row of tableRef.value.data as DtwinI[]) {
      tableRef.value.toggleRowSelection(
        row,
        props.selectedDtwins?.some(
          (selectedDevice) => selectedDevice.uid === row.uid,
        ),
      );
    }
  }
};

watch(() => props.selectedDtwins, setSelection);

const fleetFormShown: Ref<boolean> = ref(false);

const onUpdateFleetFormShown = (show: boolean) => {
  fleetFormShown.value = show;
};

async function executeSearch(replaceHistory?: boolean) {
  if (props.fleetUid) {
    setFilter(Filters.DTWINS_IN_FLEET, props.fleetUid);
  }
  if (props.notInFleetUid) {
    setFilter(Filters.DTWINS_NOT_IN_FLEET, props.notInFleetUid);
  }

  await searchOnSectors(selectedSectors.value, replaceHistory);
}

const selectedDeviceModelFilter = computed(() => {
  return filters.get(Filters.DTWIN_MODEL_TYPE);
});

const getQuickFilterButtonType = (selectedModelTypeUid: string) => {
  return selectedDeviceModelFilter?.value?.value.includes(selectedModelTypeUid)
    ? "primary"
    : "secondary";
};

const quickFiltersWrapperClass = computed(() => {
  const wrapperClass = ["flex", "gap-2"];

  if (
    props.selectedDtwins &&
    props.selectedDtwins.length > 0 &&
    isAllowedToManageFleet.value
  ) {
    wrapperClass.push("mb-2");
  } else if (props.displayDeviceCompatibilityQuickFilter) {
    wrapperClass.push("mb-[1.125rem]", "!gap-4");
  } else {
    wrapperClass.push("mb-6");
  }

  return wrapperClass;
});

async function getDevicesByModel(modelUid: string) {
  if (selectedDeviceModelFilter?.value?.value.includes(modelUid)) {
    setFilter(Filters.DTWIN_MODEL_TYPE, []);
  } else {
    setFilter(Filters.DTWIN_MODEL_TYPE, [modelUid]);
  }
  await executeSearch();
}

const selectedCompatibilityFilter: Ref<DeviceCompatibilityQuickFilter> = ref(
  DeviceCompatibilityQuickFilter.ALL_DEVICES,
);

async function filterByCompatibility(
  compatibilityFilter: DeviceCompatibilityQuickFilter,
) {
  selectedCompatibilityFilter.value = compatibilityFilter;
  await reloadData();
}

const getCompatibilityFilterButtonType = (
  compatibilityFilter: DeviceCompatibilityQuickFilter,
) => {
  return selectedCompatibilityFilter.value === compatibilityFilter
    ? "primary"
    : "tertiary";
};

const getCompatibilityFilterIconClass = (
  compatibilityFilter: DeviceCompatibilityQuickFilter,
) => {
  if (
    compatibilityFilter !== selectedCompatibilityFilter.value &&
    compatibilityFilter === DeviceCompatibilityQuickFilter.COMPATIBLE_DEVICES
  ) {
    return "text-success-700";
  } else if (
    compatibilityFilter !== selectedCompatibilityFilter.value &&
    compatibilityFilter === DeviceCompatibilityQuickFilter.INCOMPATIBLE_DEVICES
  ) {
    return "text-error-700";
  } else {
    return "text-inherit";
  }
};

const deviceCompatibilityQuickFilters: DeviceCompatibilityQuickFilterConfigI[] =
  [
    {
      filterType: DeviceCompatibilityQuickFilter.ALL_DEVICES,
    },
    {
      filterType: DeviceCompatibilityQuickFilter.COMPATIBLE_DEVICES,
      icon: ILxcCheckCircle,
    },
    {
      filterType: DeviceCompatibilityQuickFilter.INCOMPATIBLE_DEVICES,
      icon: ILxcAlertTriangle,
    },
  ];

const quickActionButtonConfig: ComputedRef<QuickActionButtonConfigI> = computed(
  () => {
    let quickActionButtonConfig: QuickActionButtonConfigI;
    switch (props.context) {
      case DtwinsListDisplayContext.FLEET:
        quickActionButtonConfig = {
          icon: ILxcTrash2,
          title: t("dtwins.fleet.removeFromFleet"),
          event: () => emit("removeFromFleet", props.selectedDtwins),
        };
        break;
      case DtwinsListDisplayContext.DTWINS:
      default:
        quickActionButtonConfig = {
          icon: ILxcFolderPlus,
          title: t("dtwins.fleet.addToFleet"),
          event: () => (fleetFormShown.value = true),
        };
        break;
    }
    return quickActionButtonConfig;
  },
);

const handleSelect = (selectedDevices: DtwinI[]) => {
  emit(
    "update:selected-dtwins",
    props.selectedDtwins
      ?.filter(
        (selectedDevice: DtwinI) =>
          !tableRef.value ||
          !(tableRef.value.data as DtwinI[])?.find(
            (row: DtwinI) => row.uid === selectedDevice.uid,
          ),
      )
      .filter(
        (selectedDevice: DtwinI) =>
          !selectedDevices.find((row) => row.uid === selectedDevice.uid),
      )
      .concat(selectedDevices) ?? selectedDevices,
  );
};

const onCancelSelection = () => {
  emit("update:selected-dtwins", []);
  if (tableRef.value) {
    tableRef.value.selectedRows = [];
  }
};

const onRowClick = (dtwin: DtwinI) => {
  emit("rowClick", dtwin);
  if (!props.noRedirection) {
    router.push(`${PATHS.DTWINS}/${dtwin.uid}`);
  }
};

const columnsWidth: ColumnNameAndWidthI<DisplayColumns>[] = [
  {
    columnName: DisplayColumns.FRIENDLY_NAME,
    width: 20,
  },
  {
    columnName: DisplayColumns.TYPE,
    width: 8,
  },
  {
    columnName: DisplayColumns.SERIAL_NUMBER,
    width: 12,
  },
  {
    columnName: DisplayColumns.SECTOR,
    width: 10,
  },
  {
    columnName: DisplayColumns.CONNECTIVITY,
    width: 8,
  },
  {
    columnName: DisplayColumns.METASTATUS,
    width: 8,
  },
  {
    columnName: DisplayColumns.STATE,
    width: 8,
  },
  {
    columnName: DisplayColumns.CREATED_AT,
    width: 12,
  },
  {
    columnName: DisplayColumns.ACTION,
    width: 6,
  },
];

const tableMinWidth: ComputedRef<string | undefined> = computed(() => {
  return calculateTableMinWidth<DisplayColumns>(columnsWidth, props.columns);
});

const getWidth = (columnName: DisplayColumns): string | undefined => {
  return getColumnWidth<DisplayColumns>(columnName, columnsWidth);
};

watch(
  () => props.fleetUid,
  () => executeSearch(),
);

onMounted(async () => {
  await fetchAllModels();
  onSearch(reloadData, false);
  await executeSearch(true);
});

defineExpose({
  onCancelSelection,
});
</script>

<template>
  <div v-if="!tableOnly" :class="`mb-6 mt-4 ${searchBarClass}`">
    <lxc-search-bar
      v-model:search-query="searchQuery"
      :search-placeholder="t('dtwins.filters.searchByNameOrSerialNumber')"
      @clear="search"
      @search="search"
    />
  </div>
  <lxc-container
    :error="errorModels"
    :is-loading="isLoadingPage"
    :px="0"
    :py="0"
    class="mb-4"
  >
    <!-- Avoid ugly display of the quick filters before the list has been displayed. The quick filter arrives with the list the first time the list is loaded -->
    <div v-if="!tableOnly && (results?.data || error)">
      <div v-if="!noQuickFilters" :class="quickFiltersWrapperClass">
        <lxc-button
          v-for="model in models?.results"
          :key="model.uid"
          :title="model.friendlyName"
          :type="getQuickFilterButtonType(model.uid)"
          class="!text-sm !py-[0.438rem] !px-3.5"
          @click="() => getDevicesByModel(model.uid)"
        >
          {{ model.friendlyName }}
        </lxc-button>
      </div>
      <div
        v-if="displayDeviceCompatibilityQuickFilter"
        :class="quickFiltersWrapperClass"
      >
        <lxc-button
          v-for="(
            compatibilityQuickFilter, index
          ) in deviceCompatibilityQuickFilters"
          :key="index"
          :title="
            t(
              `dtwins.filters.compatibilityQuickFilters.${compatibilityQuickFilter.filterType}`,
            )
          "
          :icon="compatibilityQuickFilter.icon"
          :type="
            getCompatibilityFilterButtonType(
              compatibilityQuickFilter.filterType,
            )
          "
          class="!text-sm !py-[0.438rem] !px-3.5"
          :icon-class="
            getCompatibilityFilterIconClass(compatibilityQuickFilter.filterType)
          "
          @click="filterByCompatibility(compatibilityQuickFilter.filterType)"
        >
          {{
            t(
              `dtwins.filters.compatibilityQuickFilters.${compatibilityQuickFilter.filterType}`,
            )
          }}
        </lxc-button>
      </div>
    </div>
    <div
      v-if="
        selectedDtwins &&
        selectedDtwins.length > 0 &&
        !noQuickActionMenu &&
        isAllowedToManageFleet
      "
    >
      <lxc-quick-actions-toolbar
        :quick-action-button-config="quickActionButtonConfig"
        :selection-message="
          t('dtwins.fleet.selectedDevices', selectedDtwins.length)
        "
        @cancel-selection="onCancelSelection"
      />
    </div>
    <lxc-table
      ref="tableRef"
      :min-width="tableMinWidth"
      :context="results?.context"
      :data="results?.data"
      :is-loading="
        (results?.data == undefined && error !== undefined) || isLoading
      "
      :error="error?.toError()"
      :empty-text="t('dtwins.empty')"
      :clickable="clickable"
      :row-click-selection-mode="rowClickSelectionMode"
      @change-page-and-page-size="reloadData"
      @row-click="onRowClick"
      @select="handleSelect"
      @select-all="handleSelect"
    >
      <lxc-table-column v-if="selectedDtwins" type="selection" />
      <lxc-table-column
        v-if="!columns || columns.includes(DisplayColumns.FRIENDLY_NAME)"
        prop="attributes.friendlyName"
        :label="t('dtwins.list.attributes.friendlyName')"
        :min-width="getWidth(DisplayColumns.FRIENDLY_NAME)"
      />
      <lxc-table-column
        v-if="!columns || columns.includes(DisplayColumns.TYPE)"
        prop="attributes.type"
        :label="t('dtwins.list.attributes.type')"
        :width="getWidth(DisplayColumns.TYPE)"
      >
        <template v-if="models" #default="scope">
          {{ getDtwinTypeLabel(models.results, scope.row) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(DisplayColumns.SERIAL_NUMBER)"
        prop="attributes.serialNumber"
        :label="t('dtwins.list.attributes.serialNumber')"
        :width="getWidth(DisplayColumns.SERIAL_NUMBER)"
      />
      <lxc-table-column
        v-if="!columns || columns.includes(DisplayColumns.SECTOR)"
        prop="attributes.sectorId"
        :label="t('dtwins.list.attributes.sector')"
        :width="getWidth(DisplayColumns.SECTOR)"
      >
        <template #default="scope">
          {{ getSectorLabel(scope.row.attributes.sectorId) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(DisplayColumns.CONNECTIVITY)"
        :label="t('dtwins.list.attributes.connectivity.name')"
        class="!py-0"
        :width="getWidth(DisplayColumns.CONNECTIVITY)"
      >
        <template #default="scope">
          <LxcDtwinsListConnectivityStatus
            v-if="scope.row.connectivityStatus"
            :connectivity-status="scope.row.connectivityStatus"
            :connectivity-status-parameters="
              getConnectivityStatusParameters(
                scope.row.deviceModelUid,
                models?.results,
              )
            "
            :center="true"
          />
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(DisplayColumns.METASTATUS)"
        :label="t('dtwins.list.attributes.metastatus.name')"
        class="!py-0"
        :width="getWidth(DisplayColumns.METASTATUS)"
      >
        <template #default="scope">
          <LxcDtwinsListMetadataStatus
            v-if="scope.row.operationStatuses"
            :operation-statuses="scope.row.operationStatuses"
          />
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(DisplayColumns.STATE)"
        :label="t('dtwins.list.attributes.state')"
        :width="getWidth(DisplayColumns.STATE)"
      >
        <template #default="scope">
          {{ t(`dtwins.lifeCycleState.${scope.row.lifeCycleState}`) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="!columns || columns.includes(DisplayColumns.CREATED_AT)"
        :label="t('dtwins.list.attributes.createdAt')"
        :width="getWidth(DisplayColumns.CREATED_AT)"
      >
        <template #default="scope">
          {{ formatIsoDate(scope.row.createdAt, t("dateFormat.datetime")) }}
        </template>
      </lxc-table-column>
      <lxc-table-column
        v-if="
          (columns && columns.includes(DisplayColumns.ACTION)) ||
          context === DtwinsListDisplayContext.DTWINS
        "
        class="!py-0"
        :width="getWidth(DisplayColumns.ACTION)"
      >
        <template #default="scope">
          <lxc-dtwins-actions
            :dtwin="scope.row"
            :context="context"
            :is-allowed-to-manage-fleet="isAllowedToManageFleet"
            :is-removing-from-fleet="isRemovingFromFleet"
            @remove-from-fleet="$emit('removeFromFleet', [scope.row])"
            @life-cycle-state-updated="reloadData"
          />
        </template>
      </lxc-table-column>
    </lxc-table>
  </lxc-container>
  <lxc-fleet-form
    v-if="!hideAddToFleetPanel"
    :fleet-form-shown="fleetFormShown"
    :dtwin-list="selectedDtwins"
    @update:fleet-form-shown="onUpdateFleetFormShown"
    @clear-dtwin-list="onCancelSelection"
  />
</template>
<style lang="scss" scoped>
:deep(button) {
  span {
    margin-left: 0 !important;
  }
}

:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;

          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
</style>

<script setup lang="ts">
import { defineProps } from "vue";
import {
  computeCampaignStats,
  DisplayedStatistic,
} from "~/components/campaigns/campaignDetail/campaignOperationManagerDetail/campaignStatisticsComputation.utils";
import type { ICampaignStatsItem } from "~/components/campaigns/campaignList/campaignStatsItem.interface";
import ILxcActivity from "~icons/lxc/activity";
import ILxcCalendar from "~icons/lxc/calendar";
import ILxcCheck from "~icons/lxc/check";
import ILxcX from "~icons/lxc/x";

const props = defineProps<{
  operationStatistics: Record<string, number>;
}>();

const { t } = useI18n();

const statistics: Ref<ICampaignStatsItem[]> = ref<ICampaignStatsItem[]>([
  {
    type: DisplayedStatistic.PENDING,
    icon: ILxcCalendar,
    colorClass: "",
    tooltip: "campaign.list.column.pending.label",
  },
  {
    type: DisplayedStatistic.IN_PROGRESS,
    icon: ILxcActivity,
    colorClass: "",
    tooltip: "campaign.list.column.inProgress.label",
  },
  {
    type: DisplayedStatistic.SUCCESS,
    icon: ILxcCheck,
    colorClass: "text-success-700",
    tooltip: "campaign.list.column.done.label",
  },
  {
    type: DisplayedStatistic.FAILED,
    icon: ILxcX,
    colorClass: "text-error-700",
    tooltip: "campaign.list.column.failed.label",
  },
]);
</script>

<template>
  <div class="flex items-center">
    <template v-for="(stat, index) in statistics" :key="stat.type">
      <span :title="t(stat.tooltip)">
        <component :is="stat.icon" class="inline" :class="stat.colorClass" />
      </span>
      <span
        class="ml-1.5"
        :class="{ 'mr-2': index !== statistics.length - 1 }"
        >{{ computeCampaignStats(props.operationStatistics, stat.type) }}</span
      >
    </template>
  </div>
</template>

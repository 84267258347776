import type { SectorI } from "@lxc/app-device-types";
import { defineStore } from "pinia";
import type { LocationQuery } from "vue-router";
import SectorsService from "~/services/sectors.service";
import { Filters } from "~/types";

export const useSectorStore = defineStore("sectorStore", () => {
  const allSectors = ref<SectorI[]>([]);
  const selectedSectors = ref<SectorI[]>([]);
  const availableSectors = ref<SectorI[]>([]);
  const selectedSectorCodes = ref<string[]>([]);
  let isInitialized = false;

  function getSectorCodes(sectors: SectorI[]): string[] {
    return sectors.map((sector) => sector.code);
  }

  function getSectorName(sectorId: string): string | undefined {
    const sector: SectorI | undefined = availableSectors.value.find(
      (currentSector) => {
        return currentSector.code === sectorId;
      },
    );
    return sector?.label;
  }

  function changeSelection(newSelection: SectorI[]) {
    selectedSectors.value = newSelection;
    selectedSectorCodes.value = getSectorCodes(newSelection);
  }

  async function retrieveAllSectors() {
    allSectors.value = SectorsService.flattenSectors(
      await SectorsService.getSectors(),
    );
  }

  async function retrieveAvailableSectors() {
    availableSectors.value = await SectorsService.getAllFlattenSectors();
  }

  async function resetSectorsSelection() {
    selectedSectors.value = [];
    selectedSectorCodes.value = [];
    await retrieveAvailableSectors();
  }

  /**
   * Initialize the store using the parameters in the URL.
   * This method can be called multiple times but the initialization will only be done the first time.
   *
   * @param query query to use to initialize the store
   */
  async function init(query: LocationQuery) {
    if (!isInitialized) {
      isInitialized = true;
      await retrieveAvailableSectors();
      const sectors = query[Filters.SECTORS + "[]"] as string[];

      if (sectors !== undefined) {
        const selectedSectors = availableSectors.value.filter(
          (sector: SectorI) => sectors.includes(sector.code),
        );
        changeSelection(selectedSectors);
      } else {
        selectedSectors.value = availableSectors.value;
      }
    }
  }

  return {
    init,
    allSectors,
    selectedSectors,
    selectedSectorCodes,
    availableSectors,
    changeSelection,
    resetSectorsSelection,
    retrieveAllSectors,
    getSectorName,
  };
});

<script setup lang="ts">
import {
  computeCampaignStats,
  DisplayedStatistic,
} from "../campaignDetail/campaignOperationManagerDetail/campaignStatisticsComputation.utils";
import { SortMode } from "@lxc/app-device-common";
import type { CampaignOperationMgrI } from "@lxc/app-device-types";
import { useCampaignOperationMgr } from "~/composables/useCampaignOperationMgr";
import { PATHS } from "~/constants/paths";
import { Filters } from "~/types";
import { formatIsoDate } from "~/utils/date-tools";

const {
  isLoading,
  error,
  results,
  filters,
  fetchData,
  setFilter,
  search,
  onSearch,
  onSortChange,
} = useCampaignOperationMgr();

const { t } = useI18n();
const router = useRouter();

async function loadData(page?: number, pageSize?: number) {
  await fetchData(page, pageSize);
}

const searchQuery = computed({
  get() {
    return (filters.get(Filters.NAME) ?? "") as string;
  },
  set(searchQuery) {
    setFilter(Filters.NAME, searchQuery);
  },
});

const onSelectCampaign = (row: CampaignOperationMgrI) => {
  router.push(`${PATHS.CAMPAIGNS}/${row.uid}`);
};

const countDevicesFromOperationStatistics = (
  statistics?: Record<string, number>,
) => {
  if (statistics === undefined) {
    return 0;
  }
  return Object.values(statistics).reduce((acc, curr) => acc + curr, 0);
};

onSearch(loadData);
</script>
<template>
  <div class="mb-4">
    <lxc-search-bar
      v-model:search-query="searchQuery"
      :search-placeholder="t('filters.searchByName')"
      @clear="search"
      @search="search"
    />
  </div>
  <lxc-table
    min-width="76rem"
    :is-loading="isLoading"
    :error="error?.toError()"
    :data="results?.data"
    :context="results?.context"
    :empty-text="t('campaign.list.empty')"
    clickable
    @row-click="onSelectCampaign"
    @change-page-and-page-size="loadData"
    @sort-change="onSortChange"
  >
    <lxc-table-column
      prop="name"
      :label="t('campaign.list.column.name.label')"
      :sort-mode="SortMode.CUSTOM"
      min-width="10rem"
    />
    <lxc-table-column
      prop="launchPlannedAt"
      :label="t('campaign.list.column.dates.label')"
      :sort-mode="SortMode.CUSTOM"
      width="15rem"
    >
      <template #default="scope">
        {{
          formatIsoDate(
            scope.row.launchPlannedAt,
            t("campaign.list.column.dates.format"),
          )
        }}
        →
        {{
          formatIsoDate(
            scope.row.expiredAt,
            t("campaign.list.column.dates.format"),
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="deviceCount"
      :label="t('campaign.list.column.numberOfDevice.label')"
      width="10rem"
    >
      <template #default="scope">
        {{
          t(
            "campaign.list.column.numberOfDevice.value",
            countDevicesFromOperationStatistics(scope.row.operationStatistics),
          )
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="status"
      :label="t('campaign.list.column.status.label')"
      class="!py-0"
      width="9rem"
    >
      <template #default="scope">
        <lxc-campaign-operation-mgr-status-badge :status="scope.row.status" />
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="status"
      :label="t('campaign.list.column.operations')"
      class="!py-0"
      width="18rem"
      :no-tooltip="true"
    >
      <template #default="scope">
        <lxc-campaign-stats
          :operation-statistics="scope.row.operationStatistics"
        />
      </template>
    </lxc-table-column>
  </lxc-table>
</template>

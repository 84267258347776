export enum DisplayableColumns {
  NAME,
  RANGE,
  DECLINATION,
  VERSION,
  BUILD_DATE,
  CREATION_DATE,
  STATUS,
  ACTIONS,
}

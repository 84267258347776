import type { ColumnNameAndWidthI } from "~/types/table.type";

export function calculateTableMinWidth<T>(
  columnsNameAndWidth: ColumnNameAndWidthI<T>[],
  displayedColumns?: T[],
): string | undefined {
  let tableMinWidth: number = 0;
  if (!displayedColumns) {
    columnsNameAndWidth.forEach(
      (columnNameAndWidth: ColumnNameAndWidthI<T>) => {
        tableMinWidth += columnNameAndWidth.width ?? 0;
      },
    );
  } else {
    displayedColumns.forEach((column: T) => {
      tableMinWidth +=
        columnsNameAndWidth.find(
          (columnNameAndWidth: ColumnNameAndWidthI<T>) => {
            return columnNameAndWidth.columnName === column;
          },
        )?.width ?? 0;
    });
  }

  return tableMinWidth > 0 ? `${tableMinWidth}rem` : undefined;
}

export function getColumnWidth<T>(
  columnName: T,
  columnsNameAndWidth: ColumnNameAndWidthI<T>[],
): string | undefined {
  const columnWidth = columnsNameAndWidth.find(
    (column) => column.columnName === columnName,
  )?.width;
  return columnWidth ? `${columnWidth}rem` : undefined;
}

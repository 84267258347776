<script setup lang="ts">
import type {
  ApplicationI,
  CampaignConfigI,
  CampaignI,
  DeviceI,
  FirmwareI,
  LicenseStatusFeatureByDeviceTypeI,
  LicenseStatusI,
} from "@lxc/app-device-types";
import {
  AppDeviceState,
  CampaignState,
  CampaignType,
  FirmwareStatus,
  LicenseStatusFeatureByDeviceTypeStatus,
  ObjectCampaignState,
  ObjectCampaignType,
} from "@lxc/app-device-types";
import dayjs from "dayjs";
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { DisplayableColumns } from "~/components/parameters/firmwares/LxcFirmwaresList.type";
import { useApplication } from "~/composables/useApplication";
import { useDevices } from "~/composables/useDevices";
import { SearchMode } from "~/composables/useSearch";
import {
  appModelDeclinationOptions,
  appModelTypeOptions,
  appStateOptions,
  appTypeDeclinationMap,
} from "~/constants/applicationsFilters.config";
import {
  deviceModelOptions,
  deviceTypeDeclinationMap,
  s4ListTypeOptions,
  stateOptions,
  typeOptions,
} from "~/constants/deviceFilters.config";
import { PATHS } from "~/constants/paths";
import { router } from "~/plugins/router";
import campaignService from "~/services/campaign.service";
import firmwareService from "~/services/firmware.service";
import { useLicenseStatusStore } from "~/stores/useLicenseStatusStore";
import { useUserSession } from "~/stores/useUserSession";
import type { FilterOptions } from "~/types";
import { Filters } from "~/types";
import { FeaturesName } from "~/types/deviceFeatures";
import { Roles } from "~/types/roles";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcInfoFilled from "~icons/lxc-custom/info-filled";
import ILxcQuestionCircle from "~icons/lxc-custom/question-circle";
import ILxcLightCalendar from "~icons/lxc-light/calendar";
import ILxcLightClock from "~icons/lxc-light/clock";
import ILxcAlertCircle from "~icons/lxc/alert-circle";
import ILxcArrowLeft from "~icons/lxc/arrow-left";
import ILxcPlusCircle from "~icons/lxc/plus-circle";
import ILxcTrash2 from "~icons/lxc/trash-2";

const props = defineProps<{
  campaignId?: number | string;
  show?: boolean;
}>();

const emit = defineEmits(["update:show"]);

/**
 * Uses
 */
const { t } = useI18n();
const { licenseStatus } = storeToRefs(useLicenseStatusStore());
const { hasActionScheduled: deviceHasActionScheduled } = useDevices();
const { hasActionScheduled: applicationHasActionScheduled } = useApplication();
/** */

/**
 * Local variables
 */
const campaign: Ref<CampaignI | undefined | null> = ref();
const error: Ref<LxcError | undefined | null> = ref();
const step = ref(1);
const isSubmitting = ref(false);
const firmwareLoading = ref(false);
const selectedDevices: Ref<DeviceI[]> = ref([]);
const selectedApplications: Ref<ApplicationI[]> = ref([]);
const selectedFirmware: Ref<FirmwareI | undefined | null> = ref();
const selectedCampaignType: Ref<selectedCampaignTypeEnum | undefined> = ref();
// Firmware filters and options
const rangeOptions: Ref<FilterOptions | undefined> = ref();
// Device filters and options
const deviceTypeFilters: Ref<string[]> = ref([]);
const deviceTypeFilterOptions: Ref<FilterOptions | undefined> = ref();
const deviceModelFilterOptions: Ref<FilterOptions | undefined> = ref();
const deviceStateFilterOptions: Ref<FilterOptions | undefined> = ref();
// Application options
const applicationTypeFilterOptions: Ref<FilterOptions | undefined> = ref();
const applicationModelFilterOptions: Ref<FilterOptions | undefined> = ref();
const applicationStateFilterOptions: Ref<FilterOptions | undefined> = ref();
/** */

/**
 * Local interfaces
 */
interface CampaignSettingsI {
  name: string | undefined;
  type: CampaignType | undefined;
  firmwareUuid: string | undefined;
}

interface InclusionPeriodI {
  day: string;
  start: string;
  end: string;
}

interface CampaignPlanningExecutionDatesI {
  plannedStartAt: Date | undefined;
  plannedEndAt: Date | undefined;
}

interface CampaignPlanningInclusionPeriodsI {
  inclusionPeriods: InclusionPeriodI[];
}

enum selectedCampaignTypeEnum {
  FIRMWARE = "firmware",
  CERTIFICATE = "certificate",
}
/** */

/**
 * Forms
 */
const campaignSettingsFormRef: Ref<HTMLFormElement | undefined | null> = ref();
const campaignSettingsForm = reactive<CampaignSettingsI>({
  name: undefined,
  type: undefined,
  firmwareUuid: undefined,
});
watch(selectedFirmware, () => {
  if (selectedFirmware.value?.uuid !== campaignSettingsForm.firmwareUuid) {
    campaignSettingsForm.firmwareUuid = selectedFirmware.value?.uuid;
    selectedDevices.value = [];
  }
});

watch(selectedCampaignType, () => {
  if (!props.campaignId) {
    switch (selectedCampaignType.value) {
      case selectedCampaignTypeEnum.FIRMWARE:
        // Set campaign type to upgrade firmware
        campaignSettingsForm.type = CampaignType.FIRMWARE_UPGRADE_DVC;
        break;
      case selectedCampaignTypeEnum.CERTIFICATE:
        // Reset selected firmware if campaign type changes to avoid filtering device list on previously selected firmware
        selectedFirmware.value = undefined;
        break;
      // Reset campaign type if selectedCampaignType is unselected
      default:
        campaignSettingsForm.type = undefined;
    }

    // Reset selected devices or application if campaign type changes
    selectedDevices.value = [];
    selectedApplications.value = [];
    deviceTypeFilters.value = [];
  }
});

function isSelectedFirmware(): boolean {
  if (!campaignSettingsForm.firmwareUuid) {
    showNotificationError(
      t("campaign.firmware.update.FirmwareSelectionRequired"),
    );
    return false;
  } else {
    return true;
  }
}

const campaignPlanningExecutionDatesFormRef: Ref<
  HTMLFormElement | undefined | null
> = ref();
const campaignPlanningExecutionDatesForm =
  reactive<CampaignPlanningExecutionDatesI>({
    plannedStartAt: undefined,
    plannedEndAt: undefined,
  });

const campaignPlanningInclusionPeriodsFormRef: Ref<
  HTMLFormElement | undefined | null
> = ref();
const campaignPlanningInclusionPeriodsForm =
  reactive<CampaignPlanningInclusionPeriodsI>({
    inclusionPeriods: [],
  });
/** */

/**
 * Rules
 */

// Campaign settings rules
const campaignSettingsFormRules = {
  name: [
    {
      required: true,
      message: t("campaign.funnel.campaignSettings.validation.name"),
      whitespace: true,
      trigger: "blur",
    },
    {
      max: 100,
      message: t("input.error.maxLength", { maxLength: 100 }),
      whitespace: true,
      trigger: "blur",
    },
  ],
  type: [
    {
      required: true,
      message: t("campaign.funnel.campaignSettings.validation.type"),
      trigger: "change",
    },
  ],
};

const userSessionStore = useUserSession();
const disabledUpdateFirmwareCheckbox = computed(() => {
  return (
    !!campaign.value ||
    !(
      userSessionStore.userSession?.roles.includes(
        Roles.DVTM_DVT_FIRM_UPDATE,
      ) &&
      userSessionStore.userSession?.roles.includes(Roles.DVTM_FIRM_VIEW) &&
      userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_VIEW)
    )
  );
});
const disabledUpdateCertificateCheckbox = computed(() => {
  return (
    !!campaign.value ||
    !(
      (userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_CRT_RENEW) ||
        userSessionStore.userSession?.roles.includes(
          Roles.DVTM_APP_CRT_RENEW,
        )) &&
      userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_VIEW)
    )
  );
});
const disabledUpdateDeviceCertificateCheckbox = computed(() => {
  return (
    !!campaign.value ||
    !userSessionStore.userSession?.roles.includes(Roles.DVTM_DVT_CRT_RENEW)
  );
});
const disabledUpdateApplicationCertificateCheckbox = computed(() => {
  return (
    !!campaign.value ||
    !userSessionStore.userSession?.roles.includes(Roles.DVTM_APP_CRT_RENEW)
  );
});

// Possibility to edit the campaign on:
//   - creation
//   - or modification if the campaign is scheduled only
const canEditCampaign = computed(
  () => !campaign.value || campaign.value.state === CampaignState.SCHEDULED,
);

// Check if the start date has been validated
const isStartDateValidated = ref(false);
const isStartDateValid = (rule: any, value: any, callback: any) => {
  // To consider checkDate and now time without seconds
  const checkDate = new Date(value);
  checkDate.setSeconds(0);
  checkDate.setMilliseconds(0);
  const now = new Date();
  now.setSeconds(0);
  now.setMilliseconds(0);
  const expirationDate = licenseStatus.value
    ? new Date(
        Date.parse(licenseStatus.value.expirationDate) +
          parseInt(licenseStatus.value.toleranceExpirationDate) *
            24 *
            60 *
            60 *
            1000,
      )
    : new Date();

  // Check license expiration date with tolerance
  if (licenseStatus.value && checkDate.getTime() < expirationDate.getTime()) {
    // Make the check if user don't use "Now" button. If now button is used, the check will be true
    if (checkDate.getTime() !== now.getTime() && !isStartDateValidated.value) {
      if (canEditCampaign.value && checkDate.getTime() < now.getTime()) {
        isStartDateValidated.value = false;
        callback(new Error(t("campaign.dateInferiorToToday")));
      } else {
        isStartDateValidated.value = true;
        callback();
      }
    } else {
      isStartDateValidated.value = true;
      callback();
    }
  } else {
    isStartDateValidated.value = false;
    callback(
      new Error(
        t(
          "campaign.funnel.campaignPlanification.executionDates.validation.startDateNotBeyondLicenseExpirationDateTolerancy",
        ),
      ),
    );
  }
};

// Check if the end date has been validated
const isEndDateValid = (rule: any, value: any, callback: any) => {
  const expirationDate = licenseStatus.value
    ? new Date(
        Date.parse(licenseStatus.value.expirationDate) +
          parseInt(licenseStatus.value.toleranceExpirationDate) *
            24 *
            60 *
            60 *
            1000,
      )
    : new Date();

  if (value) {
    // Check license expiration date with tolerance
    if (licenseStatus.value && Date.parse(value) < expirationDate.getTime()) {
      if (canEditCampaign.value) {
        // adding 24 hours to plannedStartAt to avoid plannedEndDate to be the same as plannedStartDate
        const plannedStartAtPlusOneDay =
          campaignPlanningExecutionDatesForm.plannedStartAt?.getTime()
            ? campaignPlanningExecutionDatesForm.plannedStartAt?.getTime() +
              1440 * 60000
            : "";

        if (
          campaignPlanningExecutionDatesForm.plannedStartAt &&
          value.getTime() < plannedStartAtPlusOneDay
        ) {
          callback(
            new Error(
              t("campaign.dateInferiorToDate", {
                date: dayjs(new Date(plannedStartAtPlusOneDay)).format(
                  t("campaign.dateFormat"),
                ),
              }),
            ),
          );
        } else {
          callback();
        }
      } else {
        callback();
      }
    } else {
      callback(
        new Error(
          t(
            "campaign.funnel.campaignPlanification.executionDates.validation.endDateNotBeyondLicenseExpirationDateTolerancy",
          ),
        ),
      );
    }
  } else {
    callback();
  }
};

// Campaign planning execution dates rules
const campaignPlanningExecutionDatesFormRules = {
  plannedStartAt: [
    {
      type: "date",
      required: true,
      message: t(
        "campaign.funnel.campaignPlanification.executionDates.validation.plannedStartAt",
      ),
      trigger: "update:model-value",
    },
    { validator: isStartDateValid, trigger: "update:model-value" },
  ],
  plannedEndAt: [
    {
      type: "date",
      required: true,
      message: t(
        "campaign.funnel.campaignPlanification.executionDates.validation.plannedEndAt",
      ),
      trigger: "update:model-value",
    },
    { validator: isEndDateValid, trigger: "change" },
  ],
};

const disabledEndDate = computed(() =>
  dayjs(campaignPlanningExecutionDatesForm.plannedStartAt)
    .add(1, "days")
    .toDate(),
);

// Validate that the span time of an inclusion period slot is at least 15 minutes
const campaignPlanningInclusionPeriodsStartValidator = (
  rule: any,
  value: any,
  callback: any,
) => {
  // Get index of the start field
  const index = rule.field.split("[")[1].split("]")[0];
  // Set the value of start field in milliseconds
  const [valueHour, valueMinutes] = value.split(":");
  const valueMilliseconds = new Date().setHours(valueHour, valueMinutes);
  // Get the value of the end and set in milliseconds
  const [endHour, endMinutes] =
    campaignPlanningInclusionPeriodsForm.inclusionPeriods[index].end.split(":");
  const endMilliseconds = new Date().setHours(
    parseInt(endHour),
    parseInt(endMinutes),
  );

  if (valueMilliseconds + 15 * 60000 > endMilliseconds) {
    callback(
      new Error(
        t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.range",
        ),
      ),
    );
  } else {
    callback();
  }
};

// Validate that the span time of an inclusion period slot is at least 15 minutes
const campaignPlanningInclusionPeriodsEndValidator = (
  rule: any,
  value: any,
  callback: any,
) => {
  // Get index of the end field
  const index = rule.field.split("[")[1].split("]")[0];
  // Set the value of end field in milliseconds
  const [valueHour, valueMinutes] = value.split(":");
  const valueMilliseconds = new Date().setHours(valueHour, valueMinutes);
  // Get the value of the start and set in milliseconds
  const [startHour, startMinutes] =
    campaignPlanningInclusionPeriodsForm.inclusionPeriods[index].start.split(
      ":",
    );
  const startMilliseconds = new Date().setHours(
    parseInt(startHour),
    parseInt(startMinutes),
  );

  if (valueMilliseconds < startMilliseconds + 15 * 60000) {
    callback(
      new Error(
        t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.range",
        ),
      ),
    );
  } else {
    callback();
  }
};

// Campaign planning inclusion periods rules
const campaignPlanningInclusionPeriodsFormRules = computed(() => {
  const campaignPlanningInclusionPeriodsFormRules: Record<string, any[]> = {};

  for (const index in campaignPlanningInclusionPeriodsForm.inclusionPeriods) {
    campaignPlanningInclusionPeriodsFormRules[
      `inclusionPeriods[${index}].day`
    ] = [
      {
        type: "string",
        required: true,
        message: t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.day",
        ),
        trigger: "update:model-value",
      },
    ];
    campaignPlanningInclusionPeriodsFormRules[
      `inclusionPeriods[${index}].start`
    ] = [
      {
        type: "string",
        required: true,
        message: t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.start",
        ),
        trigger: "update:model-value",
      },
      {
        validator: campaignPlanningInclusionPeriodsStartValidator,
        trigger: "update:model-value",
      },
    ];
    campaignPlanningInclusionPeriodsFormRules[
      `inclusionPeriods[${index}].end`
    ] = [
      {
        type: "string",
        required: true,
        message: t(
          "campaign.funnel.campaignPlanification.inclusionPeriods.validation.end",
        ),
        trigger: "update:model-value",
      },
      {
        validator: campaignPlanningInclusionPeriodsEndValidator,
        trigger: "update:model-value",
      },
    ];
  }

  return campaignPlanningInclusionPeriodsFormRules;
});

// Validate form depending on current step
async function isFormValid() {
  switch (step.value) {
    case 1:
      if (campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC) {
        return (
          (await campaignSettingsFormRef.value
            ?.validate()
            .catch(() => false)) && isSelectedFirmware()
        );
      } else {
        return await campaignSettingsFormRef.value
          ?.validate()
          .catch(() => false);
      }
    case 2:
      if (
        selectedDevices.value.length === 0 &&
        selectedApplications.value.length === 0
      ) {
        showNotificationError(
          t("campaign.funnel.fleetSelection.validation.required"),
        );
        return false;
      } else {
        return true;
      }
    case 3: {
      return (
        (await campaignPlanningExecutionDatesFormRef.value
          ?.validate()
          .catch(() => false)) &
        (await campaignPlanningInclusionPeriodsFormRef.value
          ?.validate()
          .catch(() => false))
      );
    }
  }
}
/** */

/**
 * Get campaign if ID is define in URL
 */
onMounted(async () => {
  if (props.campaignId) {
    const response = await campaignService.getCampaign(
      props.campaignId.toString(),
    );

    if (LxcError.check(response)) {
      error.value = response;
    } else {
      campaign.value = response;

      // Set campaign settings
      campaignSettingsForm.name = response.config?.name ?? "";
      campaignSettingsForm.type = response.type;
      if (campaignSettingsForm.type !== CampaignType.FIRMWARE_UPGRADE_DVC) {
        selectedCampaignType.value = selectedCampaignTypeEnum.CERTIFICATE;
      } else {
        selectedCampaignType.value = selectedCampaignTypeEnum.FIRMWARE;
      }

      switch (campaignSettingsForm.type) {
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          campaignSettingsForm.firmwareUuid =
            response.config?.firmwareUpgrade?.uuid;
          await getFirmware(campaignSettingsForm.firmwareUuid);
          break;
        case CampaignType.CRTCLT_RENEWAL_DVC:
          selectedDevices.value = (response.objects ?? [])
            .filter((object) => object.state === ObjectCampaignState.SCOPED)
            .map((object) => {
              return {
                id: object.id,
                name: object.label,
                model: {
                  type: object.deviceType,
                },
              };
            });
          break;
        case CampaignType.CRTCLT_RENEWAL_APP:
          selectedApplications.value = (response.objects ?? [])
            .filter((object) => object.state === ObjectCampaignState.SCOPED)
            .map((object) => {
              return {
                id: object.id,
                name: object.label,
                model: {
                  type: object.deviceType,
                },
              };
            });
          break;
      }

      // Set planned dates
      if (response.config?.plannedStartAt) {
        campaignPlanningExecutionDatesForm.plannedStartAt = new Date(
          response.config?.plannedStartAt,
        );
      }
      if (response.config?.plannedEndAt) {
        campaignPlanningExecutionDatesForm.plannedEndAt = new Date(
          response.config?.plannedEndAt,
        );
      }

      // Set inclusion periods
      if (response.config?.inclusionPeriods) {
        campaignPlanningInclusionPeriodsForm.inclusionPeriods =
          response.config?.inclusionPeriods.map((inclusionPeriod) => {
            return {
              day: inclusionPeriod.day,
              start: inclusionPeriod.startTimeSlot
                ? inclusionPeriod.startTimeSlot
                : "",
              end: inclusionPeriod.endTimeSlot
                ? inclusionPeriod.endTimeSlot
                : "",
            };
          });
      }
    }
  }
});
/** */

/** Get Firmware if Editing firmware update campaign */
const firmwareError: Ref<LxcError | null> = ref(null);
async function getFirmware(firmwareUuid?: string) {
  if (firmwareUuid) {
    // Get the selected firmware infos
    const response = await firmwareService.getFirmwareByUuid(firmwareUuid);

    if (LxcError.check(response)) {
      firmwareError.value = response;
    } else {
      selectedFirmware.value = response;
    }
  } else {
    selectedFirmware.value = null;
  }
}

/** Funnel steps */
const showConfirm = ref(false);
// Go to the next steps
const next = async () => {
  if (await isFormValid()) {
    if (step.value === 1) {
      // Reset selected devices or application if campaign type changes
      if (campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_DVC) {
        selectedApplications.value = [];
      }
      if (campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_APP) {
        selectedDevices.value = [];
      }
    }
    // Check and display confirm if the selected equipments has actions scheduled, continue if not
    let found = false;
    if (step.value === 2) {
      const campaignType = campaignSettingsForm.type;
      switch (campaignType) {
        case CampaignType.CRTCLT_RENEWAL_DVC:
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          found = !!selectedDevices.value.find(
            (selectedDevice: DeviceI) =>
              deviceHasActionScheduled(selectedDevice, campaignType) === true,
          );
          break;
        case CampaignType.CRTCLT_RENEWAL_APP:
          found = !!selectedApplications.value.find(
            (selectedApplication: ApplicationI) =>
              applicationHasActionScheduled(
                selectedApplication,
                campaignType,
              ) === true,
          );
          break;
      }
      if (found) {
        showConfirm.value = true;
      } else {
        step.value++;
      }
    } else {
      step.value++;
    }
  }
};

// Return to previous steps
const previous = () => {
  if (step.value > 0) {
    step.value--;
  }
};

const onConfirmContinueCreateUpdate = () => {
  showConfirm.value = false;
  step.value++;
};
/** */

/**
 * Inclusion periods
 */
function addInclusionPeriod() {
  campaignPlanningInclusionPeriodsForm.inclusionPeriods.push({
    day: "",
    start: "",
    end: "",
  });
}

function removeInclusionPeriod(index: number) {
  campaignPlanningInclusionPeriodsForm.inclusionPeriods.splice(index, 1);
}

const inclusionPeriodsOptions = [
  {
    value: "MONDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.monday",
    ),
  },
  {
    value: "TUESDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.tuesday",
    ),
  },
  {
    value: "WEDNESDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.wednesday",
    ),
  },
  {
    value: "THURSDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.thursday",
    ),
  },
  {
    value: "FRIDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.friday",
    ),
  },
  {
    value: "SATURDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.saturday",
    ),
  },
  {
    value: "SUNDAY",
    label: t(
      "campaign.funnel.campaignPlanification.inclusionPeriods.days.sunday",
    ),
  },
];
/** */

async function onSubmit() {
  if (await isFormValid()) {
    if (campaignSettingsForm.type) {
      isSubmitting.value = true;
      const plannedStartAt = campaignPlanningExecutionDatesForm.plannedStartAt
        ? campaignPlanningExecutionDatesForm.plannedStartAt.toISOString()
        : "";
      const plannedEndAt = campaignPlanningExecutionDatesForm.plannedEndAt
        ? campaignPlanningExecutionDatesForm.plannedEndAt.toISOString()
        : "";

      let config: CampaignConfigI | undefined;
      let campaignObjects: object[] = [];

      switch (campaignSettingsForm.type) {
        case CampaignType.FIRMWARE_UPGRADE_DVC:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
            firmwareUpgrade: {
              uuid: campaignSettingsForm.firmwareUuid,
            },
          };

          campaignObjects = selectedDevices.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.DVC,
              label: device.name,
              deviceType: device.model?.type,
            };
          });
          break;
        case CampaignType.CRTCLT_RENEWAL_DVC:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
          };

          campaignObjects = selectedDevices.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.DVC,
              label: device.name,
              deviceType: device.model?.type,
            };
          });
          break;
        case CampaignType.CRTCLT_RENEWAL_APP:
          config = {
            name: campaignSettingsForm.name,
            plannedStartAt,
            plannedEndAt,
          };

          campaignObjects = selectedApplications.value.map((device) => {
            return {
              id: device.id,
              type: ObjectCampaignType.APP,
              label: device.name,
              deviceType:
                device.model?.type === "S4G-Tools"
                  ? "S4-Tools"
                  : device.model?.type, // Temporary fix
            };
          });
          break;
      }

      if (campaignPlanningInclusionPeriodsForm.inclusionPeriods.length > 0) {
        // Add inclusion periods
        config.inclusionPeriods =
          campaignPlanningInclusionPeriodsForm.inclusionPeriods.map(
            (inclusionPeriod: InclusionPeriodI) => {
              return {
                day: inclusionPeriod.day,
                startTimeSlot: inclusionPeriod.start,
                endTimeSlot: inclusionPeriod.end,
              };
            },
          );
      }

      // Update the campaign
      if (campaign.value) {
        if (
          campaign.value.state === CampaignState.SCHEDULED &&
          campaign.value.config?.plannedStartAt &&
          Date.parse(campaign.value.config.plannedStartAt) <= Date.now()
        ) {
          showNotificationError(t(NotificationKey.startDateError));
        } else {
          const campaignToUpdate: CampaignI = {
            id: campaign.value.id,
            type: campaignSettingsForm.type,
            objects: campaignObjects,
          };
          // Add config only if the campaign is not started
          if (campaign.value.state === CampaignState.SCHEDULED) {
            campaignToUpdate.config = config;
            if (!campaignToUpdate.config.inclusionPeriods) {
              campaignToUpdate.config.inclusionPeriods = [];
            }
          }

          const response =
            await campaignService.updateCampaign(campaignToUpdate);

          if (LxcError.check(response)) {
            response.notify(NotificationKey.saveError);
          } else {
            showNotificationSuccess(t(NotificationKey.saveSuccess));
            await router.push(
              `${PATHS.CAMPAIGNS}/${campaign.value.id}/${PATHS.DETAILS_SUBPATH}`,
            );
            emit("update:show", false);
          }
        }
      }
      // Create a campaign
      else {
        const response = await campaignService.createCampaign({
          type: campaignSettingsForm.type,
          config,
          objects: campaignObjects,
        });

        if (LxcError.check(response)) {
          response.notify(NotificationKey.saveError);
        } else {
          showNotificationSuccess(t(NotificationKey.saveSuccess));
          await router.push(
            `${PATHS.CAMPAIGNS}/${response.id}/${PATHS.DETAILS_SUBPATH}`,
          );
        }
      }

      isSubmitting.value = false;
    }
  }
}

/**
 * Filter devices if beyond the limit of the license
 */
function filterDevices(status?: LicenseStatusI | null) {
  deviceTypeFilters.value = [];
  const devicesAdvancedFleetManagementFeature:
    | LicenseStatusFeatureByDeviceTypeI[]
    | undefined = status?.featureByDeviceTypes.filter((feature) => {
    return feature.name === FeaturesName.ADVANCED_FLEET_MGT;
  });
  devicesAdvancedFleetManagementFeature?.forEach((deviceType) => {
    if (
      deviceType.status !==
        LicenseStatusFeatureByDeviceTypeStatus.SUSPENDED_DEVICE &&
      deviceType.status !== LicenseStatusFeatureByDeviceTypeStatus.NOT_ACTIVATED
    ) {
      if (deviceType.deviceType === "S4-Tools") {
        // Temporary fix
        deviceTypeFilters.value.push("S4G-Tools");
      } else {
        deviceTypeFilters.value.push(deviceType.deviceType);
      }
    }
  });
}

/**
 * Build filter options from options by calculating the disabled property with the provided callback function
 * @param filterOptions
 * @param isFilterOptionDisabled
 */
function buildFilterOptions(
  filterOptions: FilterOptions,
  isFilterOptionDisabled?: (opt: string) => boolean,
): FilterOptions {
  return {
    label: filterOptions.label,
    options: filterOptions.options.map((option) => {
      return {
        label: option.label,
        value: option.value,
        disabled: isFilterOptionDisabled
          ? isFilterOptionDisabled(option.value)
          : false,
      };
    }),
  };
}

/**
 * Check if the filter device model option is disabled or not depending on its parent device type
 */
function isFilterOptionDeviceModelDisabled(optionValue: string): boolean {
  let isParentDisabled = false;
  for (const key in deviceTypeDeclinationMap) {
    if (
      deviceTypeDeclinationMap[key].deviceDeclinations.includes(optionValue)
    ) {
      deviceTypeFilterOptions.value?.options.forEach((option) => {
        if (option.value === key) {
          isParentDisabled = option.disabled ?? false;
        }
      });
    }
  }
  return isParentDisabled;
}

/**
 * Check if the filter application model option is disabled or not depending on its parent application type
 */
function isFilterOptionAppModelDeclinationDisabled(
  optionValue: string,
): boolean {
  let isParentDisabled = false;
  for (const key in appTypeDeclinationMap) {
    if (
      appTypeDeclinationMap[key].applicationDeclinations.includes(optionValue)
    ) {
      applicationTypeFilterOptions.value?.options.forEach((option) => {
        if (option.value === key) {
          isParentDisabled = option.disabled ?? false;
        }
      });
    }
  }
  return isParentDisabled;
}

/**
 * List filters
 */
const firmwaresListDefaultFilters = computed(() => {
  filterDevices(licenseStatus.value);
  rangeOptions.value = buildFilterOptions(typeOptions);
  return new Map<Filters, any>([
    [Filters.STATUS, [FirmwareStatus.ACTIVATED]],
    [Filters.RANGE, deviceTypeFilters.value],
  ]);
});

const devicesListDefaultFilters = computed(() => {
  filterDevices(licenseStatus.value);
  deviceTypeFilterOptions.value = buildFilterOptions(
    s4ListTypeOptions,
    (optionValue) => {
      // disable the device type filter options if the campaign is a firmware upgrade or if the options are disabled by the license
      return (
        campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC ||
        !deviceTypeFilters.value.includes(optionValue)
      );
    },
  );
  deviceModelFilterOptions.value = buildFilterOptions(
    deviceModelOptions,
    isFilterOptionDeviceModelDisabled,
  );
  deviceStateFilterOptions.value = buildFilterOptions(stateOptions, () => true);
  return new Map<Filters, any>([
    [Filters.STATE, [AppDeviceState.ACTIVATED]],
    [
      Filters.MODEL_TYPE,
      selectedFirmware.value?.range
        ? [selectedFirmware.value?.range]
        : deviceTypeFilters.value,
    ],
    [
      Filters.MODEL_DECLINATION,
      selectedFirmware.value?.declination
        ? [selectedFirmware.value?.declination]
        : [],
    ],
    [
      Filters.DEVICE_FIRMWARE_VERSIONS,
      selectedFirmware.value?.firmwareVersions,
    ],
    [
      Filters.DEVICE_HARDWARE_VERSIONS,
      selectedFirmware.value?.hardwareVersions,
    ],
  ]);
});

const applicationsListDefaultFilters = computed(() => {
  filterDevices(licenseStatus.value);
  applicationTypeFilterOptions.value = buildFilterOptions(appModelTypeOptions);
  applicationModelFilterOptions.value = buildFilterOptions(
    appModelDeclinationOptions,
    isFilterOptionAppModelDeclinationDisabled,
  );
  applicationStateFilterOptions.value = buildFilterOptions(
    appStateOptions,
    () => true,
  );
  return new Map<Filters, any>([
    [Filters.STATE, [AppDeviceState.ACTIVATED]],
    [Filters.MODEL_TYPE, deviceTypeFilters.value],
  ]);
});

const statusOptions: FilterOptions = {
  label: t("filters.status"),
  options: Object.values(FirmwareStatus).map((value) => {
    return {
      value,
      label: `firmware.status.${value}`,
      disabled: true,
    };
  }),
};
</script>

<template>
  <lxc-common-modal
    :show="show"
    is-full-screen
    with-steps
    :step="step"
    :number-of-steps="3"
    @close="$emit('update:show', false)"
  >
    <template #header>
      <h2 v-if="step === 1">
        {{
          campaignId
            ? t("campaign.funnel.updateCampaign")
            : t("campaign.funnel.newCampaign")
        }}
      </h2>
      <h2 v-if="step === 2">
        {{ t("campaign.funnel.fleetSelection.title") }}
      </h2>
      <h2 v-if="step === 3">
        {{ t("campaign.funnel.scheduling") }}
      </h2>
      <p>
        {{ t("campaign.funnel.step") }} {{ step }}
        {{ t("campaign.funnel.of") }} 3
      </p>
      <lxc-button
        v-if="step > 1"
        :title="t('campaign.funnel.previous')"
        type="tertiary"
        class="w-11 h-11 absolute top-1/2 -translate-y-1/2 left-8"
        @click="previous"
      >
        <ILxcArrowLeft />
      </lxc-button>
    </template>
    <template #body>
      <div class="mt-14">
        <!-- Campaign settings step -->
        <div v-if="step === 1">
          <lxc-form
            ref="campaignSettingsFormRef"
            :model="campaignSettingsForm"
            :rules="campaignSettingsFormRules"
            @submit.prevent
          >
            <h4 class="mb-10">
              {{ t("campaign.funnel.campaignSettings.title") }}
            </h4>
            <lxc-form-item
              :label="t('campaign.funnel.campaignSettings.name')"
              class="w-80 mb-6"
              prop="name"
            >
              <lxc-input
                v-model="campaignSettingsForm.name"
                type="text"
                placeholder="Name"
                :disabled="!canEditCampaign"
              />
            </lxc-form-item>
            <lxc-form-item
              :label="
                t('campaign.funnel.campaignSettings.typeOfCampaign.title')
              "
              display-label
              prop="type"
            >
              <div class="flex gap-6">
                <div
                  :class="`flex items-center py-4 rounded-xl px-4 min-w-[12.6875rem] ${selectedCampaignType === selectedCampaignTypeEnum.FIRMWARE ? 'bg-primary-50 border-2 border-primary-600' : 'bg-white border border-gray-300'}`"
                >
                  <lxc-checkbox
                    v-model="selectedCampaignType"
                    :value="selectedCampaignTypeEnum.FIRMWARE"
                    :label="
                      t(
                        'campaign.funnel.campaignSettings.typeOfCampaign.firmwareUpdate.label',
                      )
                    "
                    :disabled="disabledUpdateFirmwareCheckbox"
                  />
                </div>
                <div
                  :class="`flex items-center py-4 rounded-xl px-4 min-w-[12.6875rem] ${selectedCampaignType === selectedCampaignTypeEnum.CERTIFICATE ? 'bg-primary-50 border-2 border-primary-600' : 'bg-white border border-gray-300'}`"
                >
                  <lxc-checkbox
                    v-model="selectedCampaignType"
                    :value="selectedCampaignTypeEnum.CERTIFICATE"
                    :label="
                      t(
                        'campaign.funnel.campaignSettings.typeOfCampaign.certificate',
                      )
                    "
                    :disabled="disabledUpdateCertificateCheckbox"
                  />
                </div>
              </div>
              <div
                v-if="
                  selectedCampaignType === selectedCampaignTypeEnum.CERTIFICATE
                "
                class="mt-4"
              >
                <hr />
                <div class="mt-4 flex gap-6">
                  <div
                    :class="`flex items-center py-4 rounded-xl px-4 ${campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_DVC ? 'bg-primary-50 border-2 border-primary-600' : 'bg-white border border-gray-300'}`"
                  >
                    <lxc-checkbox
                      v-model="campaignSettingsForm.type"
                      :value="CampaignType.CRTCLT_RENEWAL_DVC"
                      :label="
                        t(
                          'campaign.funnel.campaignSettings.typeOfCampaign.deviceCertificateRenewal',
                        )
                      "
                      :disabled="disabledUpdateDeviceCertificateCheckbox"
                    />
                  </div>
                  <div
                    :class="`flex items-center py-4 rounded-xl px-4 ${campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_APP ? 'bg-primary-50 border-2 border-primary-600' : 'bg-white border border-gray-300'}`"
                  >
                    <lxc-checkbox
                      v-model="campaignSettingsForm.type"
                      :value="CampaignType.CRTCLT_RENEWAL_APP"
                      :label="
                        t(
                          'campaign.funnel.campaignSettings.typeOfCampaign.applicationCertificateRenewal',
                        )
                      "
                      :disabled="disabledUpdateApplicationCertificateCheckbox"
                    />
                  </div>
                </div>
              </div>
            </lxc-form-item>

            <div
              v-if="selectedCampaignType === selectedCampaignTypeEnum.FIRMWARE"
              class="mt-4"
            >
              <hr />
              <lxc-container
                :is-loading="firmwareLoading"
                :error="firmwareError"
                :px="0"
              >
                <div class="mt-4">
                  <lxc-alert type="warning">
                    <span class="break-normal">
                      {{ t("campaign.firmware.update.warningMessage") }}
                    </span>
                  </lxc-alert>
                </div>
                <div class="mt-4">
                  <p class="text-gray-800 dark:text-white pb-[0.750rem]">
                    {{
                      t(
                        "campaign.funnel.campaignSettings.typeOfCampaign.firmwareUpdate.title",
                      )
                    }}
                  </p>
                  <div
                    v-if="
                      !campaign || campaign?.state === CampaignState.SCHEDULED
                    "
                  >
                    <p class="flex opacity-70 text-sm">
                      <ILxcInfoFilled class="mr-2 w-5" />
                      <span class="self-center">{{
                        t("campaign.firmware.update.firmwareSelection")
                      }}</span>
                    </p>
                    <lxc-firmwares-list
                      v-model:selected-firmware="selectedFirmware"
                      no-action
                      selectable
                      :columns="[
                        DisplayableColumns.NAME,
                        DisplayableColumns.VERSION,
                        DisplayableColumns.RANGE,
                        DisplayableColumns.DECLINATION,
                      ]"
                      :search-mode="SearchMode.FILTER_SEARCH"
                      :default-filters="firmwaresListDefaultFilters"
                      :status-options="statusOptions"
                      :range-options="rangeOptions"
                      display-info
                    />
                  </div>
                  <div v-else>
                    <lxc-firmware-info v-model:firmware="selectedFirmware" />
                  </div>
                </div>
              </lxc-container>
            </div>
          </lxc-form>
        </div>
        <!-- Equipment fleet selection step -->
        <div v-if="step === 2">
          <lxc-devices-list
            v-if="
              campaignSettingsForm.type === CampaignType.FIRMWARE_UPGRADE_DVC ||
              campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_DVC
            "
            v-model:selected-devices="selectedDevices"
            no-action
            :default-filters="devicesListDefaultFilters"
            :campaign-type="campaignSettingsForm.type"
            :search-mode="SearchMode.FILTER_SEARCH"
            :type-options="deviceTypeFilterOptions"
            :model-options="deviceModelFilterOptions"
            :state-options="deviceStateFilterOptions"
          />
          <lxc-applications-list
            v-if="campaignSettingsForm.type === CampaignType.CRTCLT_RENEWAL_APP"
            v-model:selected-applications="selectedApplications"
            no-action
            :default-filters="applicationsListDefaultFilters"
            :campaign-type="campaignSettingsForm.type"
            :search-mode="SearchMode.FILTER_SEARCH"
            :type-options="applicationTypeFilterOptions"
            :model-options="applicationModelFilterOptions"
            :state-options="applicationStateFilterOptions"
          />
          <lxc-confirm-modal
            :is-dialog-visible="showConfirm"
            :title="
              t(
                'campaign.funnel.fleetSelection.actions.scheduled.confirm.title',
              )
            "
            :description="
              t(
                'campaign.funnel.fleetSelection.actions.scheduled.confirm.message',
              )
            "
            :ok-label="
              t('campaign.funnel.fleetSelection.actions.scheduled.confirm.yes')
            "
            :cancel-label="
              t('campaign.funnel.fleetSelection.actions.scheduled.confirm.no')
            "
            :icon="ILxcAlertCircle"
            icon-color-theme="warning"
            @confirm="onConfirmContinueCreateUpdate"
            @cancel="showConfirm = false"
          />
        </div>
        <!-- Campaign planning step -->
        <div v-if="step === 3">
          <h4 class="mb-10">
            {{ t("campaign.funnel.campaignPlanification.title") }}
          </h4>

          <lxc-form
            ref="campaignPlanningExecutionDatesFormRef"
            :model="campaignPlanningExecutionDatesForm"
            :rules="campaignPlanningExecutionDatesFormRules"
          >
            <div class="flex gap-4">
              <lxc-form-item
                :label="
                  t(
                    'campaign.funnel.campaignPlanification.executionDates.plannedStartAt',
                  )
                "
                prop="plannedStartAt"
              >
                <lxc-input
                  v-model="campaignPlanningExecutionDatesForm.plannedStartAt"
                  type="datetime"
                  class="!h-[2.625rem]"
                  prefix-read-only
                  :disabled="!canEditCampaign"
                  :prefix-disabled="!canEditCampaign"
                  :min-date="new Date()"
                  @update:model-value="() => (isStartDateValidated = false)"
                >
                  <template #prefix>
                    <span class="text-gray-700">
                      <ILxcLightCalendar width="1.25rem" height="1.25rem" />
                    </span>
                  </template>
                </lxc-input>
              </lxc-form-item>
              <lxc-form-item
                :label="
                  t(
                    'campaign.funnel.campaignPlanification.executionDates.endDate',
                  )
                "
                prop="plannedEndAt"
              >
                <lxc-input
                  v-model="campaignPlanningExecutionDatesForm.plannedEndAt"
                  type="datetime"
                  class="!h-[2.625rem]"
                  prefix-read-only
                  :disabled="!canEditCampaign"
                  :prefix-disabled="!canEditCampaign"
                  :min-date="disabledEndDate"
                >
                  <template #prefix>
                    <span class="text-gray-700">
                      <ILxcLightCalendar width="1.25rem" height="1.25rem" />
                    </span>
                  </template>
                </lxc-input>
              </lxc-form-item>
            </div>
          </lxc-form>

          <div class="mt-6">
            <div class="flex justify-between w-1/2">
              <h4 class="mb-6">
                {{
                  t(
                    "campaign.funnel.campaignPlanification.inclusionPeriods.title",
                  )
                }}
              </h4>
              <p class="text-black/50 font-semibold">
                {{
                  t(
                    "campaign.funnel.campaignPlanification.inclusionPeriods.subTitle",
                  )
                }}
              </p>
            </div>
            <lxc-card class="w-1/2" body-class="!p-2">
              <lxc-information class="!my-0 text-gray-600">
                <template #component>
                  <lxc-info-sign>
                    <ILxcQuestionCircle
                      width="1.25rem"
                      height="1.25rem"
                      view-box="0 0 1.25rem 1.25rem"
                    />
                  </lxc-info-sign>
                </template>
                <template #body>
                  <p>
                    {{
                      t(
                        "campaign.funnel.campaignPlanification.inclusionPeriods.informations.period",
                      )
                    }}
                  </p>
                  <p>
                    {{
                      t(
                        "campaign.funnel.campaignPlanification.inclusionPeriods.informations.defaultValue",
                      )
                    }}
                  </p>
                </template>
              </lxc-information>
            </lxc-card>
            <!-- ! To be done later: add CTA on lxc-tag -->
            <!-- <div class="mt-4 flex gap-3">
              <lxc-tag>
                <span class="text-sm">{{ t('campaign.funnel.campaignPlanification.inclusionPeriods.informations.businessHour') }}</span>
              </lxc-tag>
              <lxc-tag>
                <span class="text-sm">{{ t('campaign.funnel.campaignPlanification.inclusionPeriods.informations.weekendHour') }}</span>
              </lxc-tag>
            </div> -->
            <lxc-form
              ref="campaignPlanningInclusionPeriodsFormRef"
              :model="campaignPlanningInclusionPeriodsForm"
              :rules="campaignPlanningInclusionPeriodsFormRules"
            >
              <div
                v-if="
                  canEditCampaign &&
                  campaignPlanningInclusionPeriodsForm.inclusionPeriods
                    .length === 0
                "
                class="w-1/2 border rounded-lg border-gray-400 p-4 mt-4"
                @click="addInclusionPeriod"
              >
                <p class="text-gray-400 flex gap-4 hover:cursor-pointer my-0">
                  <ILxcPlusCircle />
                  <span class="font-semibold self-center">{{
                    t(
                      "campaign.funnel.campaignPlanification.inclusionPeriods.addADay",
                    )
                  }}</span>
                </p>
              </div>
              <div
                v-for="(
                  inclusionPeriod, index
                ) in campaignPlanningInclusionPeriodsForm.inclusionPeriods"
                :key="inclusionPeriod.day + index"
              >
                <div
                  class="w-1/2 mt-4 bg-primary-50 py-2 px-4 rounded-lg border-2 border-primary-50 flex gap-4 focus:border-2 focus:border-primary-600 focus-within:border-2 focus-within:border-primary-600 hover:border-2 hover:border-primary-600"
                  tabindex="0"
                >
                  <lxc-form-item
                    class="!pb-0"
                    :prop="`inclusionPeriods[${index}].day`"
                  >
                    <lxc-select
                      v-model="inclusionPeriod.day"
                      :placeholder="
                        t(
                          'campaign.funnel.campaignPlanification.inclusionPeriods.selectPlaceholder',
                        )
                      "
                      :disabled="!canEditCampaign"
                      :prefix-disabled="!canEditCampaign"
                      prefix-read-only
                    >
                      <template #prefix>
                        <span class="text-gray-500">
                          <ILxcLightCalendar />
                        </span>
                      </template>
                      <lxc-option
                        v-for="inclusionPeriodOption in inclusionPeriodsOptions"
                        :key="inclusionPeriodOption.value"
                        class="pl-14"
                        :label="inclusionPeriodOption.label"
                        :value="inclusionPeriodOption.value"
                      />
                    </lxc-select>
                  </lxc-form-item>
                  <div class="flex gap-2 max-w-[15.625rem]">
                    <p class="text-gray-600 font-semibold">
                      {{
                        t(
                          "campaign.funnel.campaignPlanification.inclusionPeriods.start",
                        )
                      }}
                    </p>
                    <lxc-form-item
                      :prop="`inclusionPeriods[${index}].start`"
                      class="!pb-0"
                    >
                      <lxc-input
                        v-model="inclusionPeriod.start"
                        type="time"
                        placeholder="HH:MM"
                        prefix-read-only
                        prefix-class="!py-0 !top-1/2 -translate-y-1/2"
                        :prefix-disabled="!canEditCampaign"
                        :disabled="!canEditCampaign"
                        @blur.prevent
                      >
                        <template #prefix>
                          <span class="text-gray-700">
                            <ILxcLightClock width="1.25rem" height="1.25rem" />
                          </span>
                        </template>
                      </lxc-input>
                    </lxc-form-item>
                  </div>
                  <div class="flex gap-2 max-w-[15.625rem]">
                    <p class="text-gray-600 font-semibold">
                      {{
                        t(
                          "campaign.funnel.campaignPlanification.inclusionPeriods.end",
                        )
                      }}
                    </p>
                    <lxc-form-item
                      :prop="`inclusionPeriods[${index}].end`"
                      class="!pb-0"
                    >
                      <lxc-input
                        v-model="inclusionPeriod.end"
                        type="time"
                        class="max-w-[15.625rem]"
                        placeholder="HH:MM"
                        prefix-read-only
                        prefix-class="!py-0 !top-1/2 -translate-y-1/2"
                        :prefix-disabled="!canEditCampaign"
                        :disabled="!canEditCampaign"
                        @blur.prevent
                      >
                        <template #prefix>
                          <span class="text-gray-700">
                            <ILxcLightClock width="1.25rem" height="1.25rem" />
                          </span>
                        </template>
                      </lxc-input>
                    </lxc-form-item>
                  </div>
                  <lxc-button
                    v-if="canEditCampaign"
                    type="borderless"
                    :icon="ILxcTrash2"
                    class="!py-0"
                    :title="
                      t(
                        'campaign.funnel.campaignPlanification.inclusionPeriods.delete',
                      )
                    "
                    @click="removeInclusionPeriod(index)"
                    @click.prevent
                  />
                </div>
              </div>
            </lxc-form>
            <lxc-button
              v-if="
                campaignPlanningInclusionPeriodsForm.inclusionPeriods.length >
                  0 &&
                campaignPlanningInclusionPeriodsForm.inclusionPeriods.length <
                  20 &&
                canEditCampaign
              "
              type="tertiary"
              :title="
                t('campaign.funnel.campaignPlanification.inclusionPeriods.add')
              "
              :icon="ILxcPlusCircle"
              class="mt-3"
              @click="addInclusionPeriod"
            >
              <span class="font-semibold self-center">{{
                t("campaign.funnel.campaignPlanification.inclusionPeriods.add")
              }}</span>
            </lxc-button>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <lxc-button
        v-if="step < 3"
        :title="t('campaign.funnel.next')"
        type="primary"
        @click="next"
      >
        {{ t("campaign.funnel.next") }}
      </lxc-button>
      <lxc-button
        v-if="step === 3"
        :title="
          campaignId
            ? t('campaign.funnel.updateCampaign')
            : t('campaign.funnel.publish')
        "
        type="primary"
        :disabled="isSubmitting"
        @click="onSubmit"
      >
        {{
          campaignId
            ? t("campaign.funnel.updateCampaign")
            : t("campaign.funnel.publish")
        }}
      </lxc-button>
    </template>
  </lxc-common-modal>
</template>

<script setup lang="ts">
import { SortMode } from "@lxc/app-device-common";
import type {
  InterfaceAuthenticationType,
  InterfaceI,
  InterfaceStatus,
  UserProfileI,
} from "@lxc/app-device-types";
import { useAppInterface } from "~/composables/useAppInterface";
import { PATHS } from "~/constants/paths";
import UserProfilesService from "~/services/userProfiles.service";
import type { CloseEventI } from "~/types/closeEvent";
import { formatIsoDate } from "~/utils/date-tools";

const props = defineProps<{
  create: boolean;
  disableActivateInterfaceButton: boolean;
}>();
const emit = defineEmits(["update:create"]);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {
  isLoading,
  results,
  error,
  fetchData,
  onSearch,
  onSortChange,
  canManageThirdPartyApps,
  canViewThirdPartyApps,
} = useAppInterface();

const profileCodes: Ref<string[]> = ref([]);
const userProfiles: Ref<UserProfileI[]> = ref([]);
const selectedInterfaceClientId: Ref<string | undefined | null> = ref();

interface InterfaceListI {
  name: string;
  authenticationType: InterfaceAuthenticationType;
  profileName: string;
  updatedAt: string;
  status: InterfaceStatus;
  clientId?: string;
}

async function getProfilesCodes() {
  profileCodes.value = results.value
    ? results.value?.data.map(
        (appInterface: InterfaceI) => appInterface.profileCode,
      )
    : [];
}

async function getUserProfiles() {
  await UserProfilesService.getUserProfilesByCodes(profileCodes.value).then(
    (res) => {
      userProfiles.value = res;
    },
  );
}

async function loadData(page?: number, pageSize?: number) {
  if (page && pageSize) {
    await fetchData(page, pageSize);
  } else {
    await fetchData(
      results.value?.context.page,
      results.value?.context.pageSize,
    );
  }
  await getProfilesCodes();
  await getUserProfiles();
}

const interfacesList: ComputedRef<InterfaceListI[] | undefined> = computed(
  () => {
    return results.value?.data.map((appInterface: InterfaceI) => {
      const profile = userProfiles.value.find(
        (profile) => profile.code === appInterface.profileCode,
      );
      return {
        name: appInterface.name,
        authenticationType: appInterface.authenticationType,
        profileName: profile?.label ?? "",
        updatedAt: appInterface.updatedAt,
        status: appInterface.status,
        clientId: appInterface.clientId,
      };
    });
  },
);

const onSelectInterface = async (selectedInterface: InterfaceListI) => {
  if (selectedInterface.clientId && canViewThirdPartyApps()) {
    await router.push(
      `${PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP}/${selectedInterface.clientId}`,
    );
  }
};

const onCloseInterfaceDetail = async (event: CloseEventI) => {
  // reset to undefined because the URL of the list and the creation are the same, otherwise it stays at null
  selectedInterfaceClientId.value = undefined;

  await router.push({
    path: PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP,
    query: route.query,
  });

  if (event && event.edited) {
    await loadData();
  }
};

const initDisplayFromRoute = () => {
  if (route.path.startsWith(PATHS.PARAMETERS_APPLICATIONS_THIRD_PART_APP)) {
    selectedInterfaceClientId.value =
      (route.params.clientId as string) || undefined;
  }
};

watch(
  () => props.create,
  () => {
    selectedInterfaceClientId.value = null;
    emit("update:create", false);
  },
);
watch(route, initDisplayFromRoute);

onSearch(async () => {
  await loadData();
  initDisplayFromRoute();
});
</script>
<template>
  <lxc-table
    min-width="78rem"
    :is-loading="isLoading"
    :data="interfacesList"
    :context="results?.context"
    :error="error?.toError()"
    data-cy="interface-table"
    :clickable="canViewThirdPartyApps()"
    :empty-text="t('applicationMgt.tabs.thirdPartyApp.empty')"
    @change-page-and-page-size="loadData"
    @row-click="onSelectInterface"
    @sort-change="onSortChange"
  >
    <!-- ! Organization Name will be accessible in the future, for now we only get the organization code
    <lxc-table-column
      prop="organizationName"
      :label="t('applicationMgt.tabs.thirdPartyApp.column.organizationName')"
    /> -->
    <lxc-table-column
      prop="name"
      :label="t('applicationMgt.tabs.thirdPartyApp.column.interfaceName')"
      :sort-mode="SortMode.CUSTOM"
      min-width="15rem"
    />
    <lxc-table-column
      prop="authenticationType"
      :label="t('applicationMgt.tabs.thirdPartyApp.column.authMethod')"
      width="18rem"
    >
      <template #default="scope">
        {{
          scope.row.authenticationType
            ? t(
                `applicationMgt.tabs.thirdPartyApp.authMethod.${scope.row.authenticationType}`,
              )
            : ""
        }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="profileName"
      :label="t('applicationMgt.tabs.thirdPartyApp.column.profile')"
      width="12rem"
    />
    <lxc-table-column
      prop="updatedAt"
      :label="t('applicationMgt.tabs.thirdPartyApp.column.updatedAt')"
      :sort-mode="SortMode.CUSTOM"
      width="15rem"
    >
      <template #default="scope">
        {{ formatIsoDate(scope.row.updatedAt, t("dateFormat.datetime")) }}
      </template>
    </lxc-table-column>
    <lxc-table-column
      prop="status"
      :label="t('applicationMgt.tabs.thirdPartyApp.column.status')"
      class="!py-0"
      width="12rem"
    >
      <template #default="scope">
        <lxc-third-party-application-status-badge :status="scope.row.status" />
      </template>
    </lxc-table-column>
    <lxc-table-column class="!py-0" width="6rem">
      <template #default="scope">
        <div class="flex">
          <lxc-third-party-application-activate-deactivate-action
            :interface-client-id="scope.row.clientId"
            :interface-name="scope.row.name"
            :interface-status="scope.row.status"
            :can-manage-third-party-app="canManageThirdPartyApps()"
            :disable-activate-interface-button="disableActivateInterfaceButton"
            @change="loadData"
          />
          <lxc-third-party-application-delete-action
            :interface-client-id="scope.row.clientId"
            :interface-name="scope.row.name"
            :can-manage-third-party-apps="canManageThirdPartyApps()"
            @deleted="loadData"
          />
        </div>
      </template>
    </lxc-table-column>
  </lxc-table>
  <lxc-third-party-application-form
    :selected-interface-client-id="selectedInterfaceClientId"
    @close="onCloseInterfaceDetail"
  />
</template>
<style lang="scss" scoped>
:deep(table) {
  tbody {
    tr {
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }
}
</style>

<script setup lang="ts">
const props = defineProps<{
  title: string;
  withMarginBottom?: boolean;
}>();

const containerClasses = computed(() => {
  let classes = ["flex", " py-1"];

  if (props.withMarginBottom) {
    classes.push("mb-4");
  }

  return classes;
});
</script>

<template>
  <div :class="containerClasses">
    <h4 class="w-1/4 !mt-0 text-gray-900">
      {{ title }}
    </h4>
    <div class="w-3/4 pt-0.5">
      <div class="flex">
        <div class="w-1/2 space-y-1">
          <slot name="left" />
        </div>
        <div class="w-1/2 space-y-1 pl-8">
          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
</template>

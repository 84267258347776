<script setup lang="ts">
import { useSerialize } from "@lxc/app-device-common";
import type { FleetCreationPayloadI } from "@lxc/app-device-types";
import { FleetType } from "@lxc/app-device-types";
import { StatusCodes } from "http-status-codes";
import type { ComputedRef, Ref } from "vue";
import fleetService from "~/services/fleet.service";
import { useUserSession } from "~/stores/useUserSession";
import type { FleetFormI } from "~/types/fleet";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationError,
  showNotificationSuccess,
} from "~/utils/notifications-tools";

const props = defineProps<{
  dtwinsUids?: string[];
}>();

const emit = defineEmits(["saved", "isValidateDisabled", "isSaving"]);

const { t } = useI18n();
const serialize = useSerialize();
const { userSession } = useUserSession();

const fleetForm = reactive<FleetFormI>({
  friendlyName: "",
  type: FleetType.STATIC,
  deviceUuids: [],
});
const fleetFormRef: Ref<HTMLFormElement | undefined | null> = ref();

const fleetFormRules = {
  friendlyName: [
    {
      required: true,
      message: t("fleet.form.validation.name"),
      whitespace: true,
      trigger: "blur",
    },
    {
      max: 50,
      message: t("input.error.maxLength", { maxLength: 50 }),
      whitespace: true,
      trigger: "blur",
    },
  ],
};

const initFleetFormStringified: string = serialize(fleetForm);
const isEdited: ComputedRef<boolean> = computed(() => {
  const stringifiedForm = serialize(fleetForm);
  return stringifiedForm === initFleetFormStringified;
});
watch(
  () => isEdited.value,
  () => {
    emit("isValidateDisabled", isEdited.value);
  },
);

async function isFormValid() {
  return await fleetFormRef.value?.validate().catch((_: any) => false);
}

async function onSubmit() {
  if ((await isFormValid()) && userSession) {
    emit("isValidateDisabled", true);
    emit("isSaving", true);
    if (props.dtwinsUids) {
      fleetForm.deviceUuids = props.dtwinsUids;
    }
    const newFleet: FleetCreationPayloadI = {
      organizationId: userSession.organization.code,
      friendlyName: fleetForm.friendlyName,
      type: fleetForm.type,
      devices: fleetForm.deviceUuids,
    };

    const response = await fleetService.createFleet(newFleet);
    if (LxcError.check(response)) {
      switch (response.status) {
        case StatusCodes.CONFLICT:
          showNotificationError(
            t(NotificationKey.saveError),
            t("fleet.form.validation.uniqueName"),
          );
          break;
        default:
          response.notify(NotificationKey.saveError);
          break;
      }
    } else {
      if (fleetForm.deviceUuids && fleetForm.deviceUuids.length > 0) {
        showNotificationSuccess(
          t("fleet.form.saveSuccess", {
            count: fleetForm.deviceUuids.length,
            fleetName: response.friendlyName,
          }),
        );
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess));
      }
      onClose();
      emit("saved");
    }
  }
  emit("isValidateDisabled", false);
  emit("isSaving", false);
}

function onClose() {
  setTimeout(fleetFormRef.value?.clearValidate, 0);
  fleetForm.friendlyName = "";
}

defineExpose({
  onSubmit,
  onClose,
});

onMounted(() => {
  emit("isValidateDisabled", isEdited);
});
</script>
<template>
  <lxc-form
    ref="fleetFormRef"
    :model="fleetForm"
    :rules="fleetFormRules"
    @submit.prevent
  >
    <lxc-form-item :label="t('fleet.form.name')" prop="friendlyName">
      <lxc-input v-model="fleetForm.friendlyName" type="text" />
    </lxc-form-item>
  </lxc-form>
</template>

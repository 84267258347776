<script lang="ts" setup>
import { ref } from "vue";
import { useAcl } from "vue-simple-acl";
import { BreadcrumbConfig } from "~/components/shared/breadcrumb/breadcrumb.config";
import type { TabNavigation } from "~/components/shared/tabs/LxcTabsWithNavigation.vue";
import { useCertificateTags } from "~/composables/useCertificateTags";
import { useCertificatesACLRoles } from "~/composables/useCertificates";
import { PATHS } from "~/constants/paths";
import { ACL_ROLES } from "~/types";
import ILxcPlus from "~icons/lxc/plus";

const { t } = useI18n();
const { can } = useAcl();
const { canManageCertificates } = useCertificatesACLRoles();
const {
  error: tagsError,
  getTags,
  isLoading: isTagsLoading,
  tags,
} = useCertificateTags();

const canCreateKeystoreCertificate: ComputedRef<boolean> = computed(
  (): boolean => canManageCertificates() && !isTagsLoading.value,
);
const triggerSearch = ref<boolean>(false);
const generateSelfSignedCertificateFormShown: Ref<boolean> = ref(false);
const generateCSRFormShown: Ref<boolean> = ref(false);

const canCreateTruststoreCertificate: ComputedRef<boolean> = computed(
  (): boolean => canManageCertificates() && !isTagsLoading.value,
);
const importTruststoreCertificate: Ref<boolean> = ref(false);

const refreshLoad = () => {
  triggerSearch.value = !triggerSearch.value;
};
const onGenerateSelfSignedCertificate = () => {
  generateSelfSignedCertificateFormShown.value = true;
};

const onGenerateCSR = () => {
  generateCSRFormShown.value = true;
};

const onImportTrustStoreCertificate = () => {
  importTruststoreCertificate.value = true;
};

const isTabVisible = computed((): boolean => can(ACL_ROLES.DVT_CACRT_VIEW));

//
// Navigation section. Logic / configuration responsible for the navigation.
//
const keystoreTabIndex = 0;
const truststoreTabIndex = 1;

const tabNavigations: TabNavigation[] = [
  {
    index: keystoreTabIndex,
    redirectPath: PATHS.PARAMETERS_CERTIFICATES_KEYSTORE,
    labelTranslationKey: "certificates.tab.keystore",
    forceRefresh: true,
  },
  {
    index: truststoreTabIndex,
    redirectPath: PATHS.PARAMETERS_CERTIFICATES_TRUSTSTORE,
    labelTranslationKey: "certificates.tab.truststore",
    forceRefresh: true,
  },
];

// The current tab index is stored here as it is needed to display buttons above the tab components.
const selectedTabIndex: Ref<number | undefined> = ref();
const isKeystoreTabSelected = computed(
  (): boolean => selectedTabIndex.value === keystoreTabIndex,
);

const isTruststoreTabSelected = computed(
  (): boolean => selectedTabIndex.value === truststoreTabIndex,
);

//
// Lifecycle Hooks. See: https://vuejs.org/api/composition-api-lifecycle
//
onMounted(async () => {
  if (isTabVisible.value) {
    await getTags();
  }
});
</script>

<template>
  <lxc-container>
    <div class="flex justify-between">
      <lxc-breadcrumb :name="BreadcrumbConfig.CERTIFICATES.title" readonly />

      <div v-if="isTabVisible" class="self-end">
        <div v-if="isKeystoreTabSelected" class="flex justify-end gap-4">
          <lxc-button
            :disabled="!canCreateKeystoreCertificate"
            :icon="ILxcPlus"
            :title="t('certificates.keystore.generateSelfSignedCertificate')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onGenerateSelfSignedCertificate"
          >
            {{ t("certificates.keystore.generateSelfSignedCertificate") }}
          </lxc-button>
          <lxc-button
            :disabled="!canCreateKeystoreCertificate"
            :icon="ILxcPlus"
            :title="t('certificates.keystore.generateCSR')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onGenerateCSR"
          >
            {{ t("certificates.keystore.generateCSR") }}
          </lxc-button>
        </div>
        <div v-if="isTruststoreTabSelected">
          <lxc-button
            :disabled="!canCreateTruststoreCertificate"
            :icon="ILxcPlus"
            :title="t('certificates.truststore.uploadCertificate')"
            class="self-center"
            html-type="button"
            type="primary"
            @click="onImportTrustStoreCertificate"
          >
            {{ t("certificates.truststore.uploadCertificate") }}
          </lxc-button>
        </div>
      </div>
    </div>

    <LxcTabsWithNavigation
      v-if="isTabVisible"
      :tab-navigations="tabNavigations"
      @update:selected-tab-index="
        (newSelectedTabIndex) => (selectedTabIndex = newSelectedTabIndex)
      "
    >
      <template #[keystoreTabIndex]>
        <lxc-keystore-list
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          :trigger-search="triggerSearch"
        />
        <lxc-keystore-self-signed-certificate-generation-form
          v-model:side-canvas-shown="generateSelfSignedCertificateFormShown"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
        <lxc-keystore-csr-generation-form
          v-model:side-canvas-shown="generateCSRFormShown"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
      </template>
      <template #[truststoreTabIndex]>
        <lxc-truststore-list
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          :trigger-search="triggerSearch"
        />
        <lxc-truststore-upload-certificate
          v-model:side-canvas-shown="importTruststoreCertificate"
          :is-tags-loading="isTagsLoading"
          :tags-error="tagsError"
          :tags="tags"
          @save="refreshLoad"
        />
      </template>
    </LxcTabsWithNavigation>
  </lxc-container>
</template>

import type { DtwinI, DtwinModelI } from "@lxc/app-device-types";
import { useDtwin } from "~/composables/useDtwins";

const { getDtwinType } = useDtwin();

const awpDeviceModelName = "AWP";

export function isAWPDevice(
  models: DtwinModelI[],
  dtwin: DtwinI | null | undefined,
) {
  return getDtwinType(models, dtwin) === awpDeviceModelName;
}

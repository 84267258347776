<script setup lang="ts">
const props = defineProps<{
  appliedTags?: number;
}>();

defineEmits(["click"]);

const { t } = useI18n();
</script>

<template>
  <a
    class="font-medium whitespace-nowrap ml-3"
    href="#"
    @click.prevent="$emit('click', $event)"
  >
    {{ t("filters.resetFilter", appliedTags ?? 0) }}
  </a>
</template>

<script setup lang="ts">
import type LxcTabs from "@lxc/app-device-common/src/components/LxcTabs.vue";
import { useTabsNavigation } from "~/composables/useTabsNavigation";

/**
 * Representation of a tab used to perform navigation.
 */
export interface TabNavigation {
  /**
   * Index used to identify the tab.
   */
  index: number;
  /**
   * Path to navigate to after user interaction with the tab.
   */
  redirectPath: string;
  /**
   * Translation key used during tab label translation.
   */
  labelTranslationKey: string;
  /**
   * If set, force the component refresh after the tab navigation by triggering
   * the `onMounted` child component.
   */
  forceRefresh?: boolean;
}

interface Props {
  // Representations of the tabs used in the page.
  tabNavigations: TabNavigation[];
}
const emit = defineEmits(["update:selectedTabIndex"]);

const { tabNavigations } = defineProps<Props>();

const { t } = useI18n();
const { selectedTabIndex, onTabSelected } = useTabsNavigation({
  tabNavigations,
});

const tabsRef: Ref<typeof LxcTabs | undefined> = ref();

// Notify the parent that the tab has changed.
watch(
  () => selectedTabIndex.value,
  () => emit("update:selectedTabIndex", selectedTabIndex.value),
);

// Propagate the selected tab index after the composable initialization.
onMounted(() => {
  emit("update:selectedTabIndex", selectedTabIndex.value);
});

defineExpose({
  /**
   * The reference to the internal `lxc-tabs` component.
   */
  tabsRef,
});
</script>

<template>
  <lxc-tabs
    ref="tabsRef"
    v-model:selected-tab-index="selectedTabIndex"
    class="pt-8 pb-2"
    @on-tab-selected="onTabSelected"
  >
    <lxc-tab-pane
      v-for="tabNavigation in tabNavigations"
      :key="tabNavigation.index"
      :label="t(tabNavigation.labelTranslationKey)"
    >
      <LxcTabsWithNavigationSlot
        v-if="tabNavigation.forceRefresh"
        :tab-navigation="tabNavigation"
      >
        <template #[tabNavigation.index]>
          <slot :name="tabNavigation.index" />
        </template>
      </LxcTabsWithNavigationSlot>
      <slot v-else :name="tabNavigation.index" />
    </lxc-tab-pane>
  </lxc-tabs>
</template>

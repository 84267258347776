<script setup lang="ts">
import type { CustomElementEvent } from "@lxc/app-device-common";
import type { DeviceModelUiConfigI } from "@lxc/app-device-types";
import { WEB_COMPONENT_NAMES } from "~/components/dtwins/dtwinsForm/LxcDtwinsFormType";
import { useConfirmLeavePageStore } from "~/stores/useConfirmLeavePageStore";
import { useUserSession } from "~/stores/useUserSession";

const props = defineProps<{
  dtwinUid?: string;
  deviceModelUiConfig?: DeviceModelUiConfigI;
}>();

const { userSession } = useUserSession();

const proxyUrl = `${import.meta.env.LXC_AS_DEVICE_URL}proxy`;

/**
 * Load web component if it defined in device model ui config
 */
const loadWebComponent = () => {
  if (
    props.deviceModelUiConfig?.properties.details?.configuration?.webComponent
  ) {
    const webComponentScript = document.querySelector(
      `[data-web-component=${props.deviceModelUiConfig.properties.details.configuration.webComponent.name}]`,
    );

    if (webComponentScript === null) {
      const script = document.createElement("script");
      script.src =
        props.deviceModelUiConfig?.properties.details?.configuration?.webComponent.url;
      script.type = "module";

      script.setAttribute(
        "data-web-component",
        `${props.deviceModelUiConfig.properties.details.configuration.webComponent.name}`,
      );
      document.head.appendChild(script);
    }
  }
};

const removeWebComponent = () => {
  if (
    props.deviceModelUiConfig?.properties.details?.configuration?.webComponent
  ) {
    const webComponentScript = document.querySelector(
      `[data-web-component=${props.deviceModelUiConfig.properties.details.configuration.webComponent.name}]`,
    );
    if (webComponentScript !== null) {
      document.head.removeChild(webComponentScript);
    }
  }
};

const customElementError = async (error: CustomEvent) => {
  if (error.detail[0].response.status === 401) {
    const { logout } = await import("~/utils/auth.utils");
    await logout();
  }
};

/**
 * Listen to the Web Component events
 */
const onEvent = (event: CustomEvent) => {
  for (const detail of event.detail) {
    const customElementEvent = detail as CustomElementEvent;

    // case of event "CHANGES": the Web Component has (no) changes on its context => activate/deactivate the confirm leave page message
    if (customElementEvent.type === "CHANGES") {
      if (customElementEvent.args && customElementEvent.args.length > 0) {
        useConfirmLeavePageStore().activate(customElementEvent.args[0]);
      }
    }
  }
};

onMounted(loadWebComponent);
onUnmounted(removeWebComponent);
</script>

<template>
  <v-front-page
    v-if="
      deviceModelUiConfig?.properties.details?.configuration?.webComponent
        ?.name === WEB_COMPONENT_NAMES.V_FRONT_PAGE
    "
    :device-id="dtwinUid"
    :proxy-url="proxyUrl"
    :lang="userSession?.language"
    @error="customElementError"
    @event="onEvent"
  />
</template>

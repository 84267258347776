<script setup lang="ts">
import type {
  CampaignOperationMgrAddRemoveOperationI,
  CampaignOperationMgrCreateI,
  CampaignOperationMgrI,
  CampaignOperationMgrUpdateI,
  CampaignOperationParameterI,
  FirmwareDescriptorI,
  FirmwareI,
  FirmwareSoftwareComponentI,
  OperationModelI,
} from "@lxc/app-device-types";
import { CampaignOMStatus, CampaignOMType } from "@lxc/app-device-types";
import type { CampaignFunnelStep1 } from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/LxcCampaignOperationMgrFunnelStep1.vue";
import LxcCampaignOperationMgrFunnelStep1 from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/LxcCampaignOperationMgrFunnelStep1.vue";
import type { CampaignFunnelStep2 } from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/LxcCampaignOperationMgrFunnelStep2.vue";
import LxcCampaignOperationMgrFunnelStep2 from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/LxcCampaignOperationMgrFunnelStep2.vue";
import type { CampaignFunnelStep3 } from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/LxcCampaignOperationMgrFunnelStep3.vue";
import LxcCampaignOperationMgrFunnelStep3 from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/LxcCampaignOperationMgrFunnelStep3.vue";
import type { CampaignInclusionPeriodI } from "~/components/campaigns/campaignForm/campaignOperationMgrFunnel/campaignFunnelStep3/LxcCampaignFunnelStep3InclusionPeriods.vue";
import { CUSTOM_INPUT_PARAMETERS_NAMES } from "~/constants/campaignOperationMgr";
import CampaignOperationMgrService from "~/services/campaignOperationMgr.service";
import { useUserSession } from "~/stores/useUserSession";
import LxcError from "~/utils/LxcError";
import { getArrayDelta } from "~/utils/array-tools";
import { formatIsoDateToUtc } from "~/utils/date-tools";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";
import ILxcArrowLeft from "~icons/lxc/arrow-left";

const props = defineProps<{
  campaign?: CampaignOperationMgrI;
  show?: boolean;
}>();

const { t } = useI18n();
const { userSession } = useUserSession();

const emit = defineEmits(["update:show", "saved"]);

const step = ref(1);

// Possibility to edit the campaign on:
//   - creation
//   - or modification if the campaign is scheduled only
const canEditCampaign = computed(
  () => !props.campaign || props.campaign.status === CampaignOMStatus.SCHEDULED,
);

const campaignOperationMgrFunnelStep1: Ref<
  typeof LxcCampaignOperationMgrFunnelStep1 | undefined
> = ref();
const campaignOperationMgrFunnelStep2: Ref<
  typeof LxcCampaignOperationMgrFunnelStep2 | undefined
> = ref();
const campaignOperationMgrFunnelStep3: Ref<
  typeof LxcCampaignOperationMgrFunnelStep3 | undefined
> = ref();

const isSubmitting = ref(false);

const campaignFunnelStep1: Ref<CampaignFunnelStep1> = ref({
  name: undefined,
  operationModelType: undefined,
  firmwareUuid: undefined,
});

const campaignFunnelStep2: Ref<CampaignFunnelStep2> = ref({
  dtwins: [],
  fleets: [],
});

const campaignFunnelStep3: Ref<CampaignFunnelStep3> = ref({
  dateRange: {
    startAt: undefined,
    endAt: undefined,
  },
  inclusionPeriods: {
    periods: [],
  },
});

const selectedFirmware: Ref<FirmwareI | undefined> = ref();

// reset campaign funnel step 1 from campaign
function resetCampaignFunnelStep1() {
  campaignFunnelStep1.value.name = props.campaign?.name;
  campaignFunnelStep1.value.operationModelType = undefined; // set to undefined, it will be set after all operation models loaded
  if (
    props.campaign?.operationParametersList &&
    props.campaign.operationParametersList.length > 0
  ) {
    campaignFunnelStep1.value.firmwareUuid = props.campaign
      .operationParametersList[0].customInputParameters?.firmwareUid as string;
  } else {
    campaignFunnelStep1.value.firmwareUuid = undefined;
  }
}

// reset campaign funnel step 3 from campaign
function resetCampaignFunnelStep3() {
  if (props.campaign?.launchPlannedAt && props.campaign?.expiredAt) {
    campaignFunnelStep3.value.dateRange.startAt = new Date(
      props.campaign.launchPlannedAt,
    );
    campaignFunnelStep3.value.dateRange.endAt = new Date(
      props.campaign.expiredAt,
    );
  } else {
    campaignFunnelStep3.value.dateRange.startAt = undefined;
    campaignFunnelStep3.value.dateRange.endAt = undefined;
  }

  if (props.campaign && props.campaign.operationParametersList) {
    const operationParameterWithInclusionPeriods =
      props.campaign.operationParametersList.find((operationParameter) => {
        if (operationParameter.customInputParameters) {
          return operationParameter.customInputParameters[
            CUSTOM_INPUT_PARAMETERS_NAMES.INCLUSION_PERIODS
          ];
        }
      });
    if (operationParameterWithInclusionPeriods) {
      campaignFunnelStep3.value.inclusionPeriods.periods =
        operationParameterWithInclusionPeriods?.customInputParameters
          ?.inclusionPeriods as CampaignInclusionPeriodI[];
    }
  } else {
    campaignFunnelStep3.value.inclusionPeriods.periods = [];
  }
}

const operationModels: Ref<OperationModelI[]> = ref([]);

// set campaign type from campaign and all the operation models
function onAllOperationModelsLoaded(allOperationModels: OperationModelI[]) {
  operationModels.value = allOperationModels;
  if (!campaignFunnelStep1.value.operationModelType) {
    // only inspect the first operation in order to retrieve the settings attributes
    // because only one operation model type is possible for now
    const operationModel = allOperationModels.find((operationModel) => {
      if (
        props.campaign?.operationParametersList &&
        props.campaign.operationParametersList.length > 0
      ) {
        return (
          operationModel.uid ===
          props.campaign?.operationParametersList[0]?.operationModelUid
        );
      } else {
        return false;
      }
    });
    campaignFunnelStep1.value.operationModelType = operationModel
      ? operationModel.type
      : undefined;
  }
}

const initialSelectedDtwinsUid: Ref<string[]> = ref([]);
const initialSelectedFleetsUid: Ref<string[]> = ref([]);

async function next() {
  if (step.value === 1) {
    const isValid = await campaignOperationMgrFunnelStep1.value
      ?.validate()
      .catch(() => false);
    if (isValid) {
      step.value++;
    }
  } else if (step.value === 2) {
    const isValid = await campaignOperationMgrFunnelStep2.value
      ?.validate()
      .catch(() => false);
    if (isValid) {
      initialSelectedDtwinsUid.value =
        campaignOperationMgrFunnelStep2.value?.initialSelectedDtwinsUid;
      initialSelectedFleetsUid.value =
        campaignOperationMgrFunnelStep2.value?.initialSelectedFleetsUid;
      step.value++;
    }
  }
}

// parse the firmware descriptor and return a JSON object
const firmwareDescriptor: ComputedRef<FirmwareDescriptorI | undefined> =
  computed(() => {
    if (selectedFirmware.value?.descriptor) {
      try {
        return JSON.parse(selectedFirmware.value.descriptor);
      } catch (error) {
        console.error("Fail to parse the firmware descriptor", error);
        return undefined;
      }
    }
  });

function getCustomInputParameters(operationModel: OperationModelI) {
  const customInputParameters: Record<string, unknown> = {};

  for (const customInputParameter of operationModel.customInputParameters) {
    switch (customInputParameter.name) {
      case CUSTOM_INPUT_PARAMETERS_NAMES.DOWNLOAD_URL_COMPONENT_0:
        if (
          firmwareDescriptor.value &&
          firmwareDescriptor.value.softwareComponents
        ) {
          const softwareComponent =
            firmwareDescriptor.value.softwareComponents.find(
              (softwareComponent: FirmwareSoftwareComponentI) =>
                softwareComponent.path.includes("secured.bin"),
            );
          if (softwareComponent && softwareComponent.downloadUrl) {
            customInputParameters[
              CUSTOM_INPUT_PARAMETERS_NAMES.DOWNLOAD_URL_COMPONENT_0
            ] =
              `${softwareComponent.downloadUrl.protocol}://${softwareComponent.downloadUrl.endpoint}`;
          }
        }
        break;
      case CUSTOM_INPUT_PARAMETERS_NAMES.DOWNLOAD_URL_COMPONENT_1:
        if (
          firmwareDescriptor.value &&
          firmwareDescriptor.value.softwareComponents
        ) {
          const softwareComponent =
            firmwareDescriptor.value.softwareComponents.find(
              (softwareComponent: FirmwareSoftwareComponentI) =>
                softwareComponent.path.includes("unsecured.bin"),
            );
          if (softwareComponent && softwareComponent.downloadUrl) {
            customInputParameters[
              CUSTOM_INPUT_PARAMETERS_NAMES.DOWNLOAD_URL_COMPONENT_1
            ] =
              `${softwareComponent.downloadUrl.protocol}://${softwareComponent.downloadUrl.endpoint}`;
          }
        }
        break;
      case CUSTOM_INPUT_PARAMETERS_NAMES.TARGET_VERSION:
        customInputParameters[CUSTOM_INPUT_PARAMETERS_NAMES.TARGET_VERSION] =
          selectedFirmware.value?.version;
        break;
      case CUSTOM_INPUT_PARAMETERS_NAMES.FIRMWARE_UID:
        customInputParameters[CUSTOM_INPUT_PARAMETERS_NAMES.FIRMWARE_UID] =
          selectedFirmware.value?.uuid;
        break;
      case CUSTOM_INPUT_PARAMETERS_NAMES.INCLUSION_PERIODS:
        customInputParameters[CUSTOM_INPUT_PARAMETERS_NAMES.INCLUSION_PERIODS] =
          campaignFunnelStep3.value.inclusionPeriods.periods;
        break;
    }
  }

  return customInputParameters;
}

function getOperationParametersList(): CampaignOperationParameterI[] {
  const operationParametersList = [];

  for (const operationModel of operationModels.value) {
    if (operationModel.type === campaignFunnelStep1.value.operationModelType) {
      const operationParameters = {
        operationModelUid: operationModel.uid,
        customInputParameters: getCustomInputParameters(operationModel),
      };

      operationParametersList.push(operationParameters);
    }
  }

  return operationParametersList;
}

function getUpdateCampaignPayload(): CampaignOperationMgrUpdateI {
  const campaignOperationMgrUpdate: CampaignOperationMgrUpdateI = {
    updatedBy: userSession?.username || "",
    compatibilityCriteria:
      campaignOperationMgrFunnelStep2.value?.compatibilityCriteria,
    operationParametersList: getOperationParametersList(),
  };

  const dtwinsDelta = getArrayDelta(
    initialSelectedDtwinsUid.value,
    campaignFunnelStep2.value.dtwins.map((dtwin) => dtwin.uid),
  );
  const fleetsDelta = getArrayDelta(
    initialSelectedFleetsUid.value,
    campaignFunnelStep2.value.fleets.map((fleet) => fleet.uid),
  );

  const deviceOperations: CampaignOperationMgrAddRemoveOperationI = {
    add:
      dtwinsDelta.added.length > 0
        ? (dtwinsDelta.added as string[])
        : undefined,
    remove:
      dtwinsDelta.deleted.length > 0
        ? (dtwinsDelta.deleted as string[])
        : undefined,
  };

  const fleetOperations: CampaignOperationMgrAddRemoveOperationI = {
    add:
      fleetsDelta.added.length > 0
        ? (fleetsDelta.added as string[])
        : undefined,
    remove:
      fleetsDelta.deleted.length > 0
        ? (fleetsDelta.deleted as string[])
        : undefined,
  };

  if (deviceOperations.add || deviceOperations.remove) {
    campaignOperationMgrUpdate.deviceOperations = deviceOperations;
  }

  if (fleetOperations.add || fleetOperations.remove) {
    campaignOperationMgrUpdate.fleetOperations = fleetOperations;
  }

  const launchPlannedAt =
    formatIsoDateToUtc(campaignFunnelStep3.value.dateRange.startAt) || "";
  if (launchPlannedAt !== props.campaign?.launchPlannedAt) {
    campaignOperationMgrUpdate.launchPlannedAt = launchPlannedAt;
  }

  const expiredAt =
    formatIsoDateToUtc(campaignFunnelStep3.value.dateRange.endAt) || "";
  if (expiredAt !== props.campaign?.expiredAt) {
    campaignOperationMgrUpdate.expiredAt = expiredAt;
  }

  return campaignOperationMgrUpdate;
}

function getCreateCampaignPayload(): CampaignOperationMgrCreateI {
  return {
    name: campaignFunnelStep1.value?.name || "",
    organizationId: userSession?.organisation.code || "",
    deviceTwinUidList: campaignFunnelStep2.value.dtwins.map(
      (dtwin) => dtwin.uid,
    ),
    fleetUidList: campaignFunnelStep2.value.fleets.map((fleet) => fleet.uid),
    compatibilityCriteria:
      campaignOperationMgrFunnelStep2.value?.compatibilityCriteria,
    type: CampaignOMType.STATIC,
    createdBy: userSession?.username || "",
    launchPlannedAt:
      formatIsoDateToUtc(campaignFunnelStep3.value.dateRange.startAt) || "",
    expiredAt:
      formatIsoDateToUtc(campaignFunnelStep3.value.dateRange.endAt) || "",
    operationParametersList: getOperationParametersList(),
  };
}

async function onSubmit() {
  const checkValidity: boolean[] = await campaignOperationMgrFunnelStep3.value
    ?.validate()
    .catch(() => false);
  if (!checkValidity.includes(false)) {
    isSubmitting.value = true;

    // update campaign
    if (props.campaign) {
      const campaign = getUpdateCampaignPayload();
      const response = await CampaignOperationMgrService.update(
        props.campaign.uid,
        campaign,
      );

      if (LxcError.check(response)) {
        response.notify(NotificationKey.saveError);
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess));
        emit("saved", props.campaign.uid);
      }
    }
    // create campaign
    else {
      const campaign = getCreateCampaignPayload();
      const response = await CampaignOperationMgrService.create(campaign);

      if (LxcError.check(response)) {
        response.notify(NotificationKey.saveError);
      } else {
        showNotificationSuccess(t(NotificationKey.saveSuccess));
        emit("saved", response.uid);
      }
    }

    isSubmitting.value = false;
  }
}

async function resetCampaign() {
  resetCampaignFunnelStep1();
  resetCampaignFunnelStep3();
}

// when showing the funnel:
// - reset the step to first one
// - reset campaign form
watch(
  () => props.show,
  () => {
    step.value = 1;
    resetCampaign();
  },
);

// when the campaign changed => reset the campaign form
watch(() => props.campaign, resetCampaign);

// when the operation model type changed
watch(
  () => campaignFunnelStep1.value.operationModelType,
  () => {
    // reset the dtwins list
    campaignFunnelStep2.value.dtwins = [];
    campaignFunnelStep2.value.fleets = [];

    // reset the inclusion periods
    campaignFunnelStep3.value.inclusionPeriods.periods = [];
  },
);
</script>

<template>
  <lxc-common-modal
    :show="show"
    is-full-screen
    with-steps
    :step="step"
    :number-of-steps="3"
    @close="$emit('update:show', false)"
  >
    <template #header>
      <h2 v-show="step === 1" class="text-gray-900">
        {{
          campaign
            ? t("campaign.funnel.updateCampaign")
            : t("campaign.funnel.newCampaign")
        }}
      </h2>
      <h2 v-show="step === 2" class="text-gray-900">
        {{ t("campaign.funnel.deviceFleetSelection.title") }}
      </h2>
      <h2 v-show="step === 3" class="text-gray-900">
        {{ t("campaign.funnel.scheduling") }}
      </h2>
      <lxc-button
        v-if="step > 1"
        :title="t('campaign.funnel.previous')"
        type="tertiary"
        class="w-11 h-11 absolute top-1/2 -translate-y-1/2 left-8"
        @click="step--"
      >
        <ILxcArrowLeft class="text-gray-700" height="20" width="20" />
      </lxc-button>
    </template>

    <template #body>
      <div v-show="step === 1" class="mt-7">
        <lxc-campaign-operation-mgr-funnel-step1
          ref="campaignOperationMgrFunnelStep1"
          v-model="campaignFunnelStep1"
          :is-editing="!!props.campaign"
          :can-edit="canEditCampaign"
          @select-firmware="selectedFirmware = $event"
          @all-operation-models-loaded="onAllOperationModelsLoaded"
        />
      </div>
      <div v-show="step === 2" class="mt-3">
        <lxc-campaign-operation-mgr-funnel-step2
          ref="campaignOperationMgrFunnelStep2"
          v-model="campaignFunnelStep2"
          :operation-models="operationModels"
          :operation-model-type="campaignFunnelStep1.operationModelType"
          :firmware="selectedFirmware"
          :fleet-uid="campaign?.fleetUid"
        />
      </div>
      <div v-show="step === 3">
        <lxc-campaign-operation-mgr-funnel-step3
          ref="campaignOperationMgrFunnelStep3"
          v-model="campaignFunnelStep3"
          :can-edit="canEditCampaign"
          :operation-models="operationModels"
          :operation-model-type="campaignFunnelStep1.operationModelType"
        />
      </div>
    </template>

    <template #footer>
      <lxc-button
        v-if="step < 3"
        :title="t('campaign.funnel.next')"
        type="primary"
        @click="next"
      >
        {{ t("campaign.funnel.next") }}
      </lxc-button>
      <lxc-button
        v-if="step === 3"
        :title="
          campaign
            ? t('campaign.funnel.updateCampaign')
            : t('campaign.funnel.publish')
        "
        type="primary"
        :is-loading="isSubmitting"
        @click="onSubmit"
      >
        {{
          campaign
            ? t("campaign.funnel.updateCampaign")
            : t("campaign.funnel.publish")
        }}
      </lxc-button>
    </template>
  </lxc-common-modal>
</template>

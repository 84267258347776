<script setup lang="ts">
import type {
  NewUserDataI,
  OrganizationCommon,
  UserDataI,
} from "@lxc/app-device-types";
import { storeToRefs } from "pinia";
import { VALIDATION_REGEXP } from "~/constants/constants";
import usersService from "~/services/users.service";
import { useConfigStore } from "~/stores/useConfigStore";
import { useUserSession } from "~/stores/useUserSession";
import type { UserIdentityI } from "~/types";
import LxcError from "~/utils/LxcError";
import {
  NotificationKey,
  showNotificationSuccess,
} from "~/utils/notifications-tools";

const props = defineProps<{
  user: UserDataI | NewUserDataI | null;
}>();
const emit = defineEmits(["submit"]);

const { userSession } = useUserSession();
const { t, availableLocales } = useI18n();
const { isModeAD } = storeToRefs(useConfigStore());

const isAllowedToEdit = computed(() => !props.user?.isProtected);
const organization = computed(() => {
  return (props.user?.organization ||
    userSession?.organization) as OrganizationCommon;
});
const formRef = ref();
const userIdentityForm: UserIdentityI = reactive({
  login: "",
  email: "",
  firstName: "",
  lastName: "",
  language: "fr",
  status: "ACTIVE",
  organization: organization.value,
});

const formValidationRules = reactive({
  login: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 80, message: t("input.error.maxLength", { maxLength: 80 }) },
    {
      pattern: VALIDATION_REGEXP.NO_UPPERCASE,
      message: t("input.error.lowercaseOnly"),
      trigger: "blur",
    },
  ],
  email: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 255, message: t("input.error.maxLength", { maxLength: 255 }) },
    {
      pattern: VALIDATION_REGEXP.EMAIL,
      message: t("input.error.invalidFormat"),
      trigger: "blur",
    },
  ],
  firstName: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 80, message: t("input.error.maxLength", { maxLength: 80 }) },
  ],
  lastName: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
    { max: 80, message: t("input.error.maxLength", { maxLength: 80 }) },
  ],
  language: [
    { required: true, message: t("input.error.required"), trigger: "blur" },
  ],
});

onMounted(() => {
  if (props.user) {
    Object.assign(userIdentityForm, {
      id: props.user.id,
      login: props.user.login,
      email: props.user.email,
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      language: props.user.language,
      status: props.user.status,
    });
  }
});

async function onSaveUserIdentityForm(): Promise<void> {
  const isFormValid = await formRef.value.validate().catch(() => false);

  if (isFormValid) {
    const response = !userIdentityForm.id
      ? await usersService.createUser(userIdentityForm)
      : await usersService.updateUser(userIdentityForm);

    if (LxcError.check(response)) {
      (response as LxcError).notify(NotificationKey.saveError);
    } else {
      showNotificationSuccess(t(NotificationKey.saveSuccess));
      userIdentityForm.id = response.id;
      emit("submit", response);
    }
  }
}
</script>

<template>
  <el-form
    ref="formRef"
    :rules="formValidationRules"
    :model="userIdentityForm"
    label-position="left"
    label-width="200px"
  >
    <el-form-item :label="t('input.login')" prop="login">
      <el-input
        v-model="userIdentityForm.login"
        data-cy="inputLogin"
        type="text"
        :disabled="!isAllowedToEdit"
      />
    </el-form-item>
    <el-form-item :label="t('input.email')" prop="email">
      <el-input
        v-model="userIdentityForm.email"
        data-cy="inputEmail"
        type="email"
        :disabled="!isAllowedToEdit"
      />
    </el-form-item>

    <el-form-item :label="t('input.firstname')" prop="firstName">
      <el-input
        v-model="userIdentityForm.firstName"
        data-cy="inputFirstName"
        type="text"
        :disabled="!isAllowedToEdit"
      />
    </el-form-item>
    <el-form-item :label="t('input.lastname')" prop="lastName">
      <el-input
        v-model="userIdentityForm.lastName"
        data-cy="inputLastName"
        type="text"
        :disabled="!isAllowedToEdit"
      />
    </el-form-item>

    <el-form-item
      data-cy="language"
      :label="t('input.language')"
      prop="language"
    >
      <el-select
        v-model="userIdentityForm.language"
        class="language-select"
        filterable
        :placeholder="t('input.language')"
        :disabled="!isAllowedToEdit"
      >
        <el-option
          v-for="item in availableLocales"
          :key="item"
          :label="t('input.languages.' + item)"
          :value="item"
        />
      </el-select>
    </el-form-item>

    <el-row v-if="!isModeAD" justify="end">
      <el-button
        data-cy="buttonSave"
        type="primary"
        :disabled="!isAllowedToEdit"
        @click="onSaveUserIdentityForm"
      >
        {{ t("button.next") }}
      </el-button>
    </el-row>
  </el-form>
</template>

<style lang="scss" scoped>
.language-select {
  width: 100%;
}
</style>
